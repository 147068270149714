import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Autocomplete,
  Chip,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteGiftsBrand,
  getAllGiftsBrand,
  getGiftsBrandById,
  resetGiftsBrandForm,
  saveGiftsBrandPageNum,
  saveGiftsBrandSearchBody,
} from "src/actions/gifts/brand";
import { FaCopy } from "react-icons/fa";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import useCanShow from "src/components/CanShow";

const GiftItemBrandTable = () => {
  const dispatch = useDispatch();

  const copyBrandIdRole = useCanShow("40-04");
  const editBrandRole = useCanShow("40-05");
  const deleteBrandRole = useCanShow("40-06");

  const giftItemBrandData = useSelector((state) => state.giftItemBrand.data);
  const giftItemBrand = useSelector((state) => state.giftItemBrand);
  const loading = useSelector((state) => state.giftItemBrand.reload);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const searchBody = useSelector((state) => state.giftItemBrand.searchBody);
  const [searchName, setSearchName] = useState(searchBody.name);

  useEffect(() => {
    dispatch(getAllGiftsBrand(page + 1, searchBody.pageSize, searchBody.name));
  }, [page, dispatch, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveGiftsBrandPageNum(newPage));
  };

  function deleteSelectedGiftsBrand(id) {
    dispatch(
      deleteGiftsBrand(id, {
        pageNo: page + 1,
        pageSize: searchBody.pageSize,
        name: searchName,
      })
    ).then((x) => {
      dispatch(resetGiftsBrandForm());
      setOpenDeleteDialog(false);
    });
  }

  const isActive = [
    { key: "true", name: "Active" },
    { key: "false", name: "Inactive" },
  ];

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameKu",
      headerName: "Name Kurdish",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isActive ? "Active" : "Inactive"}
          color={params.row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      field: "imageUrl",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params.row.attachment ? (
          <Box component="img" src={params.row.attachment?.url} width={150} />
        ) : null,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FaCopy size={22} />}
          label="Copy Brand Id"
          hidden={!copyBrandIdRole}
          showInMenu
          onClick={(e) => {
            navigator.clipboard.writeText(params.row.id);
            dispatch(showSuccessSnackbar("Brand Id Copied"));
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!editBrandRole}
          showInMenu
          onClick={(e) => {
            dispatch(getGiftsBrandById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete"
          hidden={!deleteBrandRole}
          showInMenu
          onClick={(e) => {
            dispatch(getGiftsBrandById(params.row.id, false)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetGiftsBrandForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() => deleteSelectedGiftsBrand(giftItemBrand.form.id)}
            selectedObject={`${giftItemBrand.form.nameEn}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveGiftsBrandSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Active" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveGiftsBrandSearchBody({
                    ...searchBody,
                    isActive: value ? value.key : "",
                  })
                );
              }}
              value={
                isActive.filter((x) => x.key === searchBody.isActive)[0] ?? null
              }
            />

            {/* <FormControlLabel
                  control={
                    <Switch
                      checked={searchBody.isActive}
                      onClick={(e) => {
                        dispatch(saveSearchBody({ ...searchBody, isActive: e.target.checked }));
                        setSearchBody({
                          ...searchBody,
                          isActive: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Is Active !"
                /> */}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  dispatch(
                    saveGiftsBrandSearchBody({
                      ...searchBody,
                      name: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                    ".highlight": {
                      backgroundColor: "#bdbdbd",
                      "&:hover": {
                        backgroundColor: "darkgrey",
                      },
                    },
                  }}
                  columns={columns}
                  rows={giftItemBrandData?.data || []}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={160}
                  pagination
                  paginationMode="server"
                  rowCount={giftItemBrandData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveGiftsBrandSearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GiftItemBrandTable;
