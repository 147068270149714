import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Chip,
  TextField,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllGoIp,
  saveGoIpPageNum,
  saveGoIpSearchBody,
} from "src/actions/goip";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { GO_IP_STATUS } from "src/utils/common";
import DateRangepicker from "src/components/DateRangePicker";

export default function GoIpTable() {
  const dispatch = useDispatch();

  const _GoIpData = useSelector((state) => state.goIp.data);
  const loading = useSelector((state) => state.goIp.reload);
  const saveGoIpPageNO = useSelector((state) => state.goIp.saveGoIpPageNum);
  const _SearchBody = useSelector((state) => state.goIp.searchBody);

  // const [page, setPage] = useState(saveGoIpPageNO ?? 0);
  const [pageSize, setPageSize] = useState(15);

  // useEffect(() => {
  //   dispatch(saveGoIpPageNum(page + 1));
  // }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllGoIp(saveGoIpPageNO + 1, _SearchBody));
  }, [saveGoIpPageNO, pageSize, _SearchBody, dispatch]);

  const handlePageChange = (newPage) => {
    dispatch(saveGoIpPageNum(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveGoIpSearchBody({
        ..._SearchBody,
        pageSize: newPageSize,
      })
    );
    setPageSize(newPageSize);
  };

  var columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      renderCell: (params) =>
        params.row.provider === "Asiacell"
          ? "Asiacell"
          : params.row.provider === "Zain"
          ? "Zain"
          : "",
    },
    {
      field: "sendMessage",
      headerName: "Send Message",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.sendMessage} followCursor placement="top">
          <Typography variant="body">{params.row.sendMessage}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: "Sender",
      flex: 1,
    },
    {
      field: "port",
      headerName: "Port",
      flex: 0.5,
    },
    {
      field: "responseMessage",
      headerName: "Response Message",
      flex: 2,
      renderCell: (params) => (
        <Tooltip
          title={<Typography>{params.row.responseMessage}</Typography>}
          followCursor
          placement="top"
        >
          <Typography noWrap variant="body">
            {params.row.responseMessage}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {moment(params.row.createdAt).format("YYYY-MM-DD / hh:mm a")}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.status}
          color={params.row.status === "Received" ? "success" : "default"}
          size="small"
        />
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              options={GO_IP_STATUS || []}
              getOptionLabel={(option) => option?.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Status" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveGoIpSearchBody({
                    ..._SearchBody,
                    status: value ? value.key : null,
                  })
                );
                dispatch(saveGoIpPageNum(0));
              }}
              value={
                GO_IP_STATUS?.filter((x) => x.key === _SearchBody.status)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangepicker
              disabled={loading}
              startDate={_SearchBody.fromCreatedAt}
              endDate={_SearchBody.toCreatedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromCreatedAt: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toCreatedAt: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromCreatedAt !== _SearchBody.fromCreatedAt ||
                  updatedFilter.toCreatedAt !== _SearchBody.toCreatedAt
                ) {
                  dispatch(
                    saveGoIpSearchBody({
                      ..._SearchBody,
                      fromCreatedAt: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toCreatedAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
                dispatch(saveGoIpPageNum(0));
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  dispatch(
                    saveGoIpSearchBody({
                      status: null,
                      fromCreatedAt: null,
                      toCreatedAt: null,
                      pageSize: 15,
                    })
                  );
                  dispatch(saveGoIpPageNum(0));
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _GoIpData.data?.map((x, index) => {
                      return {
                        ...x,
                        index: index + 1,
                      };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_GoIpData?.rowCount}
                  pageSize={pageSize}
                  page={saveGoIpPageNO}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
