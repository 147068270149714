import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  InputAdornment,
  SvgIcon,
  Autocomplete,
  Grid,
  CardContent,
  Drawer,
  Badge,
  Button,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Search as SearchIcon } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import "rsuite/dist/rsuite.min.css";
import Close from "@mui/icons-material/Close";
import { order_statuses } from "src/utils/common";
import moment from "moment";
import DateRangepicker from "src/components/DateRangePicker";
import { saveSearchBody } from "src/actions/orders";
import { getAllVendors } from "src/actions/vendors";
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa";
import { changeFiltersType } from "src/actions/users";

export default function OrdersSearchFilter({
  setPage = function () {},
  reload,
}) {
  const dispatch = useDispatch();

  const vendors = useSelector((state) => state.vendors.dataList);
  const _searchBody = useSelector((state) => state.orders.searchBody);
  const filterType = useSelector((state) => state.users.filterType);

  const [searchBody, setSearchBody] = useState(_searchBody);
  const [openSearch, setOpenSearch] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  const activeFilters = useMemo(() => {
    if (
      searchBody.number === "" &&
      searchBody.itemName === "" &&
      searchBody.phoneNumber === "" &&
      searchBody.trackingNumber === "" &&
      searchBody.from === null &&
      searchBody.to === null &&
      searchBody.status === null &&
      searchBody.region === null &&
      searchBody.hasAdditionalFees === null &&
      searchBody.isCod === null &&
      (searchBody.vendorIds === null || searchBody.vendorIds?.length === 0)
    ) {
      return 0;
    } else {
      const truthyValuesCount = {
        number: searchBody.number.length > 0 ? 1 : 0,
        itemName: searchBody.itemName.length > 0 ? 1 : 0,
        phoneNumber: searchBody.phoneNumber.length > 0 ? 1 : 0,
        trackingNumber: searchBody.trackingNumber.length > 0 ? 1 : 0,
        from: searchBody.from !== null ? 1 : 0,
        to: searchBody.to !== null ? 1 : 0,
        status: searchBody.status !== null ? 1 : 0,
        region: searchBody.region !== null ? 1 : 0,
        hasAdditionalFees: searchBody.hasAdditionalFees !== null ? 1 : 0,
        isCod: searchBody.isCod !== null ? 1 : 0,
        vendorIds: searchBody.vendorIds?.length > 0 ? 1 : 0,
      };
      const activeFilters = Object.values(truthyValuesCount).filter((item) =>
        item === 1 ? true : false
      )?.length;
      return activeFilters;
    }
  }, [searchBody]);

  const region = [
    { name: "Turkey", key: "TR" },
    { name: "United State", key: "US" },
    { name: "Europe", key: "EU" },
    { name: "Arabic Gulf", key: "AG" },
    { name: "WSM", key: "WSM" },
  ];

  const hasAdditionalFees = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const isCod = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  useEffect(() => {
    dispatch(getAllVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Drawer open={openSearch} onClose={toggleDrawer(false)}>
        <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search Order"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchBody.number}
              onChange={(e) => {
                setSearchBody({ ...searchBody, number: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Search phone number"
              variant="outlined"
              type="number"
              value={searchBody.phoneNumber}
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                var phoneNumber = e.target.value;
                setSearchBody({
                  ...searchBody,
                  phoneNumber:
                    phoneNumber[0] === "0"
                      ? phoneNumber.substring(1)
                      : phoneNumber,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search tracking number"
              variant="outlined"
              value={searchBody.trackingNumber}
              sx={{ marginRight: 1 }}
              fullWidth
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  trackingNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Search item name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchBody.itemName}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  itemName: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={order_statuses}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  status: value ? value.id : null,
                });
                setPage(0);
              }}
              value={
                order_statuses.filter((x) => x.id === searchBody.status)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              multiple
              id="vendors"
              options={vendors || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendors" />
              )}
              onChange={(event, value) => {
                var ids = value.map((x) => x.id);
                setSearchBody({
                  ...searchBody,
                  vendorIds: value ? ids : searchBody.vendorIds,
                });
                setPage(0);
              }}
              value={
                vendors?.filter((x) => searchBody.vendorIds?.includes(x.id)) ??
                []
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              options={region}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Region" />}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  region: value ? value.key : null,
                });
              }}
              value={
                region.filter((x) => x.key === searchBody.region)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={hasAdditionalFees}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Additional Fees" />
              )}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  hasAdditionalFees: value ? value.key : null,
                });
                setPage(0);
              }}
              value={
                hasAdditionalFees.filter(
                  (x) => x.key === searchBody.hasAdditionalFees
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={isCod}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="COD" />}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  isCod: value ? value.key : null,
                });
                setPage(0);
              }}
              value={isCod.filter((x) => x.key === searchBody.isCod)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangepicker
              disabled={reload}
              startDate={searchBody.from}
              endDate={searchBody.to}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: moment(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchBody.from ||
                  updatedFilter.to !== searchBody.to
                ) {
                  setSearchBody({
                    ...searchBody,
                    from: moment(searchDate[0].startDate).format("YYYY-MM-DD"),
                    to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
                setPage(0);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={reload}
              variant="contained"
              onClick={() => {
                dispatch(saveSearchBody({ ..._searchBody, ...searchBody }));
              }}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={reload}
              variant="contained"
              color="warning"
              onClick={() => {
                dispatch(
                  saveSearchBody({
                    number: "",
                    phoneNumber: "",
                    trackingNumber: "",
                    itemName: "",
                    from: null,
                    to: null,
                    status: null,
                    region: null,
                    vendorIds: null,
                    hasAdditionalFees: null,
                    isCod: null,
                    pageSize: 15,
                  })
                );
                setSearchBody({
                  itemName: "",
                  number: "",
                  phoneNumber: "",
                  trackingNumber: "",
                  from: null,
                  to: null,
                  status: null,
                  region: null,
                  hasAdditionalFees: null,
                  isCod: null,
                  vendorIds: null,
                  notes: "",
                  pageSize: 15,
                });
                setPage(0);
              }}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
      </Drawer>
      <Card>
        <CardContent style={{ paddingBottom: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Order"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={searchBody.number}
                onChange={(e) => {
                  setSearchBody({ ...searchBody, number: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    setPage(0);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Search phone number"
                variant="outlined"
                type="number"
                value={searchBody.phoneNumber}
                sx={{ marginRight: 1 }}
                onChange={(e) => {
                  var phoneNumber = e.target.value;
                  setSearchBody({
                    ...searchBody,
                    phoneNumber:
                      phoneNumber[0] === "0"
                        ? phoneNumber.substring(1)
                        : phoneNumber,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    setPage(0);
                  }
                }}
              />
            </Grid>
            {filterType === "1" ? null : (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={reload}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search tracking number"
                    variant="outlined"
                    value={searchBody.trackingNumber}
                    sx={{ marginRight: 1 }}
                    fullWidth
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        trackingNumber: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(saveSearchBody(searchBody));
                        setPage(0);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={reload}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search item name"
                    sx={{ marginRight: 1 }}
                    variant="outlined"
                    value={searchBody.itemName}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        itemName: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(saveSearchBody(searchBody));
                        setPage(0);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    options={order_statuses}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        status: value ? value.id : null,
                      });
                      dispatch(
                        saveSearchBody({
                          ...searchBody,
                          status: value ? value.id : null,
                        })
                      );
                      setPage(0);
                    }}
                    value={
                      order_statuses.filter(
                        (x) => x.id === _searchBody.status
                      )[0]
                        ? order_statuses.filter(
                            (x) => x.id === _searchBody.status
                          )[0]
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    multiple
                    id="vendors"
                    options={vendors || []}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" />
                    )}
                    onChange={(event, value) => {
                      var ids = value.map((x) => x.id);
                      setSearchBody({
                        ...searchBody,
                        vendorIds: value ? ids : searchBody.vendorIds,
                      });
                      dispatch(
                        saveSearchBody({
                          ...searchBody,
                          vendorIds: value ? ids : [],
                        })
                      );
                      setPage(0);
                    }}
                    value={
                      vendors?.filter((x) =>
                        searchBody.vendorIds?.includes(x.id)
                      ) ?? []
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    options={region}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Region" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        region: value ? value.key : null,
                      });
                      dispatch(
                        saveSearchBody({
                          ...searchBody,
                          region: value ? value.key : null,
                        })
                      );
                    }}
                    value={
                      region.filter((x) => x.key === _searchBody.region)[0] ??
                      null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={hasAdditionalFees}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Additional Fees" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        hasAdditionalFees: value ? value.key : null,
                      });
                      dispatch(
                        saveSearchBody({
                          ...searchBody,
                          hasAdditionalFees: value ? value.key : null,
                        })
                      );
                      setPage(0);
                    }}
                    value={
                      hasAdditionalFees.filter(
                        (x) => x.key === searchBody.hasAdditionalFees
                      )[0] ?? null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={isCod}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="COD" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        isCod: value ? value.key : null,
                      });
                      setPage(0);
                    }}
                    value={
                      isCod.filter((x) => x.key === searchBody.isCod)[0] ?? null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DateRangepicker
                    disabled={reload}
                    startDate={searchBody.from}
                    endDate={searchBody.to}
                    handleOnChange={(searchDate) => {
                      const updatedFilter = {
                        from: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                      };
                      if (
                        updatedFilter.from !== searchBody.from ||
                        updatedFilter.to !== searchBody.to
                      ) {
                        dispatch(
                          saveSearchBody({
                            ...searchBody,
                            from: moment(searchDate[0].startDate).format(
                              "YYYY-MM-DD"
                            ),
                            to: moment(searchDate[0].endDate).format(
                              "YYYY-MM-DD"
                            ),
                          })
                        );
                        setSearchBody({
                          ...searchBody,
                          from: moment(searchDate[0].startDate).format(
                            "YYYY-MM-DD"
                          ),
                          to: moment(searchDate[0].endDate).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }
                      setPage(0);
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={filterType === "1" ? 6 : 6}
              direction="row"
              container
              alignItems="start"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1}>
                {filterType === "1" ? (
                  <Tooltip title="Advanced Search">
                    <IconButton
                      onClick={() => {
                        setOpenSearch(true);
                      }}
                    >
                      <Badge badgeContent={activeFilters} color="error">
                        <FaFilter />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                ) : null}
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    dispatch(saveSearchBody({ ..._searchBody, ...searchBody }));
                  }}
                >
                  <Tooltip title="Search with filter" disabled={reload}>
                    <SearchIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    dispatch(
                      saveSearchBody({
                        number: "",
                        phoneNumber: "",
                        trackingNumber: "",
                        itemName: "",
                        from: null,
                        to: null,
                        status: null,
                        region: null,
                        vendorIds: null,
                        hasAdditionalFees: null,
                        isCod: null,
                        pageSize: 15,
                      })
                    );
                    setSearchBody({
                      itemName: "",
                      number: "",
                      phoneNumber: "",
                      trackingNumber: "",
                      from: null,
                      to: null,
                      status: null,
                      region: null,
                      hasAdditionalFees: null,
                      isCod: null,
                      vendorIds: null,
                      notes: "",
                      pageSize: 15,
                    });
                    setPage(0);
                  }}
                >
                  <Tooltip title="Clear filter" disabled={reload}>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Stack>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(changeFiltersType(filterType === "1" ? "0" : "1"));
                }}
              >
                {filterType === "1" ? (
                  <FaArrowDown size={30} />
                ) : (
                  <FaArrowUp size={30} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
