import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, LinearProgress, Typography, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import {
  deleteGeneralSettings,
  getAllGeneralSettings,
  getGeneralSettingsById,
  openGeneralSettingDialog,
  resetForm,
  saveSearchBody,
} from "src/actions/generalSetting";
import GeneralSettingSearchFilter from "./searchFilter";
import { CustomDialog } from "src/components/CustomDialog";
import useCanShow from "src/components/CanShow";

export default function SettingsTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("15-02");
  const deleteRole = useCanShow("15-06");

  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const _GeneralSetting = useSelector((state) => state.generalSetting);
  const searchFilter = useSelector((state) => state.generalSetting.searchBody);
  const PageNo = useSelector((state) => state.generalSetting.pageNo);
  const [page, setPage] = useState(PageNo ? PageNo : 0);

  useEffect(() => {
    setReload(true);
    dispatch(getAllGeneralSettings(page + 1, searchFilter)).then((x) => {
      setReload(false);
    });
  }, [searchFilter, page, refresh, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
    setPage(0);
  };

  function deleteSelectedImage() {
    setDeleteLoading(true);
    dispatch(deleteGeneralSettings(_GeneralSetting.form.id)).then((res) => {
      setDeleteLoading(false);
      setRefresh(!refresh);
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const shareCenterToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedImage()}
            selectedObject={_GeneralSetting.form.key}
            loading={deleteLoading}
          ></CustomDialog>
        )}
        {<GeneralSettingSearchFilter pageNo={(newPage) => setPage(newPage)} />}
      </Box>
    );
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "key",
      headerName: "Key",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        params.row.value?.toLowerCase().includes("http") ? (
          <Box
            component="img"
            src={params.row.value}
            width={200}
            style={{ objectFit: "contain" }}
          />
        ) : (
          <Typography variant="body">{params.row.value}</Typography>
        ),
    },
    {
      field: "typeString",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "isActive",
      headerName: "Is Active !",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isActive ? "Active" : "Deactivate"}
          color={params.row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / hh:mma")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            setReload(true);
            dispatch(getGeneralSettingsById(params.row.id)).then((x) => {
              setReload(false);
              dispatch(openGeneralSettingDialog(_GeneralSetting.openDialog));
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            setReload(true);
            dispatch(getGeneralSettingsById(params.row.id)).then((res) => {
              setReload(false);
            });
            setOpenDeleteDialog(true);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {shareCenterToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Box sx={{ height: { md: "calc(100vh - 260px)", xs: 1000 } }}>
            <DataGrid
              sx={{
                "& .css-204u17-MuiDataGrid-main": {
                  paddingLeft: "15px",
                },
                "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                  paddingLeft: "15px",
                },
                ".MuiDataGrid-cell": {
                  justifyContent: "center",
                },
              }}
              loading={reload}
              rows={_GeneralSetting.data}
              columns={columns}
              rowHeight={200}
              disableSelectionOnClick
              pagination
              pageSize={searchFilter.pageSize}
              page={page}
              onPageChange={(newPage) => handlePageChange(newPage)}
              onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
              rowCount={_GeneralSetting.rowCount}
              paginationMode="server"
              rowsPerPageOptions={[10, 25, 100]}
              components={{
                LoadingOverlay: LinearProgress,
              }}
            />
          </Box>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
