import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  Menu,
  MenuItem,
  Icon,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MenuIcon from "@mui/icons-material/Menu";
import RolePermission from "src/components/RolePermission";
import { getAllVendors } from "src/actions/vendors";
import {
  deleteShippingOptions,
  getAllShippingOptionsByVendor,
  getShippingOptionsById,
  resetShippingOptionsForm,
} from "src/actions/shippingOptions";

export default function ShippingOptionsTable() {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const selectedVendor = useSelector(
    (state) => state.shippingOption.selectedVendor
  );
  const _ShippingOptions = useSelector((state) => state.shippingOption.data);
  const _ShippingOption = useSelector((state) => state.shippingOption.form);
  const loading = useSelector((state) => state.shippingOption.reload);
  const pageNo = useSelector((state) => state.shippingOption.pageNo);
  const _SearchBody = useSelector((state) => state.shippingOption.searchBody);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  useEffect(() => {
    dispatch(getAllShippingOptionsByVendor(selectedVendor?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  function deleteSelectedShippingOptions(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteShippingOptions(id, pageNo, _SearchBody)).then((x) => {
      dispatch(resetShippingOptionsForm());
    });
  }

  var columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "nameEn",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price ($/Ibs)",
      flex: 1,
      renderCell: (params) => params.row.price?.toLocaleString(),
    },
    {
      field: "isDefault",
      headerName: "Is Default",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isDefault ? "Yes" : "No"}
          color={params.row.isDefault ? "primary" : "default"}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isActive ? "Yes" : "No"}
          color={params.row.isActive ? "primary" : "default"}
        />
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="Options">
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            onClick={(e) => {
              handleMenuClick(e, params);
            }}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  columns = columns.filter((column) =>
    _UserInfo.roleOEndpoint.filter((value) =>
      ["61-04", "61-05"].includes(value.Code)
    )[0]
      ? column
      : column.field !== "operations"
  );

  const userMenu = (params) => (
    <Box sx={{ display: "flex" }}>
      <Menu
        anchorEl={openMenu}
        id={params.row.id}
        open={activeItem === params}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 2px rgba(0,0,0,1))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 8,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <RolePermission permission="63-05">
          <MenuItem
            onClick={(e) => {
              dispatch(getShippingOptionsById(params.row.id, true));
            }}
          >
            <Box>
              <IconButton size="small" aria-label="edit" color="primary">
                <Icon>edit</Icon>
              </IconButton>
              Edit
            </Box>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="63-06">
          <MenuItem
            onClick={(e) => {
              dispatch(getShippingOptionsById(params.row.id)).then((x) =>
                setOpenDeleteDialog(true)
              );
            }}
          >
            <Box>
              <IconButton size="small" aria-label="edit" color="error">
                <DeleteForeverIcon />
              </IconButton>
              Delete
            </Box>
          </MenuItem>
        </RolePermission>
      </Menu>
    </Box>
  );

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedShippingOptions(_ShippingOption.id)}
            selectedObject={`${_ShippingOption.vendor?.name} - ${_ShippingOption.name}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 400px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  sx={{
                    ".highlight": {
                      backgroundColor: "#bdbdbd",
                      "&:hover": {
                        backgroundColor: "darkgrey",
                      },
                    },
                  }}
                  columns={columns}
                  rows={
                    _ShippingOptions.data?.map((item, index) => {
                      return {
                        index: index + 1,
                        ...item,
                      };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="client"
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
                {activeItem && userMenu(activeItem)}
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
