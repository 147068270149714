import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Chip,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import {
  deleteEPayments,
  getAllEPayments,
  getEPaymentsById,
  openEPaymentsDialog,
  saveSearchBody,
} from "src/actions/EPayments";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";
import { E_PAYMENT_TYPES } from "src/utils/common";
import useCanShow from "src/components/CanShow";

export default function EPaymentsTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("09-03");
  const deleteRole = useCanShow("09-04");

  const _EPayments = useSelector((state) => state.epayments);

  const [limit, setLimit] = useState(10);
  const [type, setType] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllEPayments(type)).then((x) => {});
  }, [dispatch, refresh, type]);

  const handleLimitChange = (newPageSize) => {
    setLimit(newPageSize);
  };

  function deleteEPayment() {
    dispatch(deleteEPayments(_EPayments.form.id, type)).then((res) => {
      setRefresh(!refresh);
      setOpenDeleteDialog(false);
      dispatch(
        getEPaymentsById({
          id: null,
          name: "",
          logo: "",
          type: "0",
          discountPercentage: "",
          isActive: false,
          isPersonal: false,
          isInternational: false,
          provinces: [],
        })
      );
      dispatch(getAllEPayments(0));
    });
  }

  const shareCenterToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteEPayment()}
            selectedObject={_EPayments.form.name}
          ></CustomDialog>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "logo",
      headerName: "Logo",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{ height: 110 }} component="img" src={params.row.logo} />
      ),
    },
    {
      field: "isPersonal",
      headerName: "Is Personal !",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.isPersonal
              ? "Send to my self only"
              : "Send to other person"
          }
          aria-label="active_deactivate"
        >
          <Chip
            variant="outlined"
            label={params.row.isPersonal ? "Yes" : "No"}
            color={params.row.isPersonal ? "success" : "default"}
          />
        </Tooltip>
      ),
    },
    {
      field: "isInternational",
      headerName: "Is International !",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.isInternational
              ? "Can see this payment method if has international phone number or Iraqi phone number"
              : "Can see this payment method only if has Iraqi phone number"
          }
          aria-label="active_deactivate"
        >
          <Chip
            variant="outlined"
            label={params.row.isInternational ? "Yes" : "No"}
            color={params.row.isInternational ? "success" : "default"}
          />
        </Tooltip>
      ),
    },
    {
      field: "provinces",
      headerName: "Provinces Count",
      flex: 0.7,
      renderCell: (params) => params.row?.provinces?.length,
    },
    {
      field: "discountPercentage",
      headerName: "Discount Percentage",
      flex: 0.7,
      renderCell: (params) => params.row?.discountPercentage + " %",
    },
    {
      field: "isActive",
      headerName: "Is Active !",
      flex: 0.7,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isActive ? "Yes" : "No"}
          color={params.row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("YYYY-MM-DD / hh:mm")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            dispatch(getEPaymentsById(params.row)).then((res) => {
              dispatch(openEPaymentsDialog(params.row.logo));
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            dispatch(getEPaymentsById(params.row)).then((res) => {
              setOpenDeleteDialog(true);
            });
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {shareCenterToolbar()}
      <Grid container spacing={2} padding={2}>
        <Grid item xs={4}>
          <Autocomplete
            options={E_PAYMENT_TYPES || []}
            getOptionLabel={(option) => option.value}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField required {...params} label="Type" />
            )}
            onChange={(event, value) => {
              setType(value ? value.key : 0);
              dispatch(saveSearchBody(value ? value.key : 0));
            }}
            value={E_PAYMENT_TYPES?.filter((x) => x.key === type)[0] ?? 0}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PerfectScrollbar>
            <Box>
              {!_EPayments.data ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                  <DataGrid
                    sx={{
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                      ".MuiDataGrid-cell": {
                        justifyContent: "center",
                      },
                    }}
                    loading={_EPayments.reload}
                    rows={_EPayments.data}
                    columns={columns}
                    rowHeight={150}
                    disableSelectionOnClick
                    pagination
                    paginationMode="client"
                    onPageSizeChange={(newPageSize) =>
                      handleLimitChange(newPageSize)
                    }
                    pageSize={limit}
                    rowCount={_EPayments.rowCount}
                    rowsPerPageOptions={[100]}
                    components={{
                      LoadingOverlay: LinearProgress,
                    }}
                  />
                </Box>
              )}
            </Box>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Card>
  );
}
