import axios from "../axios";

class OrderService {
  getAll(pageNo, pageSize, data) {
    if (data.vendorIds === null) {
      data.vendorIds = [];
    }
    return axios.post(`/Order/getall/${pageNo}?pageSize=${pageSize}`, data);
  }

  getAllUserOrders(userId) {
    return axios.get(`/order/getuserorders/${userId}`);
  }

  getDetails(id, justNew) {
    return axios.get(`/Order/getOrderDetails/${id}?justNew=${justNew}`);
  }

  getItemDetails(id) {
    return axios.get(`/orderitem/${id}`);
  }

  // create(data) {
  //   return axios.post("/Order/create", data);
  // }

  updateStatus(data) {
    return axios.put(`/Order/updatestatus`, data);
  }

  updateOrder(data) {
    return axios.put(`/Order/updateorder`, data);
  }

  updateOrderItem(orderId, data) {
    return axios.put(`/order/updateitem/${orderId}`, data);
  }

  rejectItem(itemId, reasonId, orderId, cancelNote) {
    return axios.delete(
      `/Order/removeitem/${itemId}/${reasonId}/${orderId}?cancelNote=${cancelNote}`
    );
  }

  getHistory(id) {
    return axios.get(`/Order/statushistories/${id}`);
  }

  updateDelivery(data) {
    return axios.put(`/order/updatedelivery`, data);
  }

  getUserByOrderNumber(OrderNumber) {
    return axios.post(`/Order/getuserbyordernumber?OrderNumber=${OrderNumber}`);
  }

  updateLockerNumber(id, LockerNumber) {
    return axios.post(
      `/Order/updatelockernumber?id=${id}&LockerNumber=${LockerNumber}`
    );
  }

  getOrderNoteHistory(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/order/getordernoteshistory/${pageNo}?${params}`);
  }

  getOrderNoteReviewersList() {
    return axios.get(`/order/getallorderreviewer/`);
  }

  updateRemainingCODAmount(orderId, amount) {
    return axios.put(`/order/updateremainingcodamount/${orderId}/${amount}`);
  }
}

export default new OrderService();
