import {
  GET_ALL_ORDERS,
  SAVE_SEARCH_BODY_ORDERS,
  UPDATE_ORDER_STATUS,
  SAVE_PAGE_NUM,
  GET_ORDER_DETAILS,
  RELOAD_ORDER_ORDER_DETAILS,
  GET_USER_ORDERS,
  GET_NOTE_HISTORY,
  SAVE_NOTE_HISTORY_SEARCH_BODY,
  OPEN_NOTE_HISTORY_DIALOG,
  SAVE_NOTE_HISTORY_PAGE_NO,
} from "../actions/type";

const initialState = {
  orderDetailsReload: false,
  data: [],
  pageCount: 0,
  rowCount: 0,
  searchBody: {
    pageSize: 15,
    number: "",
    itemName: "",
    phoneNumber: "",
    trackingNumber: "",
    from: null,
    to: null,
    status: null,
    region: null,
    hasAdditionalFees: null,
    vendorIds: null,
    isCod: null,
  },
  orderDetails: {
    items: [],
  },
  userOrdersData: {},

  // order history
  orderHistory: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  orderHistorySearchBody: {
    pageSize: 100,
    fromReviewAt: null,
    toReviewAt: null,
    reviewerId: null,
    orderiiNumber: "",
    giftOrderNumber: "",
    orderNumber: "",
  },
  orderHistoryDialog: false,
  selectedId: null,
  selectedIdType: null,
  orderHistoryPageNo: 0,
};

function orderReducer(orders = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ORDERS:
      orders.data = payload.data;
      orders.pageCount = payload.pageCount;
      orders.rowCount = payload.rowCount;
      return orders;

    case GET_USER_ORDERS:
      orders.userOrdersData = payload;
      return orders;

    case RELOAD_ORDER_ORDER_DETAILS:
      orders.orderDetailsReload = !orders.orderDetailsReload;
      return { ...orders };

    case SAVE_SEARCH_BODY_ORDERS:
      return { ...orders, searchBody: payload };

    case SAVE_PAGE_NUM:
      orders.savePageNum = payload;
      return orders;

    case UPDATE_ORDER_STATUS:
      var indexToChange = orders.data.indexOf(
        orders.data.filter((x) => x.id === payload.id)[0]
      );
      orders.data[indexToChange].status = payload.newStatus;
      return orders;

    case GET_ORDER_DETAILS:
      orders.orderDetails = payload.data;
      return orders;

    case GET_NOTE_HISTORY:
      orders.orderHistory.data = payload.data;
      orders.orderHistory.total = payload.pageCount;
      orders.orderHistory.rowCount = payload.rowCount;
      return orders;

    case SAVE_NOTE_HISTORY_SEARCH_BODY:
      return { ...orders, orderHistorySearchBody: payload };

    case OPEN_NOTE_HISTORY_DIALOG:
      return {
        ...orders,
        orderHistoryDialog: !orders.orderHistoryDialog,
        selectedId: payload.id ?? null,
        selectedIdType: payload.type ?? null,
      };

    case SAVE_NOTE_HISTORY_PAGE_NO:
      return { ...orders, orderHistoryPageNo: payload };

    default:
      return orders;
  }
}

export default orderReducer;
