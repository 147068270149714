import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Chip,
  Grid,
  InputAdornment,
  LinearProgress,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllStaffUsers,
  getByIdStaffUsers,
  openStaffUsersDialog,
  saveDynamicAuthSearchBody,
} from "src/actions/dynamicAuth";
import { FaUsersCog } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";
import CustomSpinner from "src/components/CustomSpinner";
import StaffUsersForm from "src/pages/cross/dynamicAuth/users/form/Form";

export default function DynamicAuthStaffTable() {
  const dispatch = useDispatch();

  const _DynamicAuth = useSelector((state) => state.dynamicAuth);
  const _SearchBody = useSelector(
    (state) => state.dynamicAuth.staffUsersSearchBody
  );

  const [search, setSearch] = useState(_SearchBody.search ?? "");

  useEffect(() => {
    dispatch(getAllStaffUsers(_SearchBody));
  }, [dispatch, _SearchBody]);

  const handlePageChange = (newPage) => {
    dispatch(
      saveDynamicAuthSearchBody({ ..._SearchBody, pageNo: newPage + 1 })
    );
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveDynamicAuthSearchBody({ ..._SearchBody, pageSize: newPageSize })
    );
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        params.row.active ? (
          <Typography color="green">Active</Typography>
        ) : (
          <Typography color="error">Inactive</Typography>
        ),
    },
    {
      field: "roles",
      headerName: "Role",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Box display="flex" flexWrap="wrap">
          {params.row.roles?.map((x, index) => (
            <Chip
              variant="outlined"
              key={index}
              label={x.name}
              color="primary"
              size="small"
              sx={{ margin: 0.5 }}
            />
          ))}
        </Box>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => {
              dispatch(openStaffUsersDialog()).then(() => {
                dispatch(getByIdStaffUsers(params.row));
              });
            }}
          >
            <FaUsersCog size={20} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      {_DynamicAuth.openStaffUsersDialog && <StaffUsersForm />}
      <PerfectScrollbar>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ margin: "15px 15px 0 15px" }}
            >
              <TextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Search"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.replace(/^0/, ""));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveDynamicAuthSearchBody({
                        ..._SearchBody,
                        search: search,
                      })
                    );
                  }
                }}
              />
              <Tooltip title="Clear filter">
                <IconButton
                  onClick={() => {
                    dispatch(
                      saveDynamicAuthSearchBody({
                        ..._SearchBody,
                        search: "",
                      })
                    );
                    setSearch("");
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ minWidth: 1050 }}>
              {!_DynamicAuth.dynamicAuthStaffUsersData ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: { md: "calc(100vh - 330px)", xs: 1000 } }}>
                  <DataGrid
                    sx={{
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                      ".MuiDataGrid-cell": {
                        justifyContent: "center",
                      },
                    }}
                    rows={_DynamicAuth.dynamicAuthStaffUsersData.data || []}
                    rowCount={_DynamicAuth.dynamicAuthStaffUsersData.rowCount}
                    loading={_DynamicAuth.reload}
                    columns={columns}
                    rowHeight={120}
                    disableSelectionOnClick
                    pagination
                    paginationMode="server"
                    pageSize={_SearchBody.pageSize}
                    page={_SearchBody.pageno}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) =>
                      handleLimitChange(newPageSize)
                    }
                    rowsPerPageOptions={[15, 25, 100]}
                    components={{
                      LoadingOverlay: LinearProgress,
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
