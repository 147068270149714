import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  LinearProgress,
  Chip,
  Grid,
  Typography,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CustomSpinner from "src/components/CustomSpinner";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteShippingConfig,
  getAllShippingConfigs,
  getById,
  getByIdDelete,
  resetForm,
  saveShippingConfigsPageNum,
} from "src/actions/shippingConfig";
import { CustomDialog } from "src/components/CustomDialog";
import useCanShow from "src/components/CanShow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ShippingConfigsTable() {
  const saveSCPageNo = useSelector(
    (state) => state.shippingConfig.saveSCPageNum
  );

  const updateRole = useCanShow("29-02");
  const deleteRole = useCanShow("29-03");

  const [page, setPage] = useState(saveSCPageNo ? saveSCPageNo : 0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const _shippingConfigs = useSelector((state) => state.shippingConfig.data);
  const reload = useSelector((state) => state.shippingConfig.reload);
  const _ShippingConfigs = useSelector((state) => state.shippingConfig.form);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllShippingConfigs(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(saveShippingConfigsPageNum(page));
  }, [dispatch, page]);

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
    dispatch(resetForm());
  };

  function deleteSelectedShippingConfig(id) {
    dispatch(deleteShippingConfig(id));
    closeDeleteDialog();
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.1,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "value",
      headerName: "Value ($)",
      flex: 0.7,
      headerAlign: "center",
    },
    {
      field: "notes",
      headerName: "Note",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "isPercentage",
      headerName: "Is Percentage !",
      flex: 0.7,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isPercentage ? "Yes" : "No"}
          color={params.row.isPercentage ? "info" : "default"}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!updateRole}
          showInMenu
          onClick={() => {
            dispatch(getById(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          onClick={() => {
            dispatch(getByIdDelete(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <PerfectScrollbar>
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={closeDeleteDialog}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          onDelete={() => deleteSelectedShippingConfig(_ShippingConfigs?.id)}
          selectedObject={`${_ShippingConfigs?.type} Shipping Configure`}
        ></CustomDialog>
        <Box>
          {!_shippingConfigs ? (
            <CustomSpinner />
          ) : (
            <Box>
              {reload && <LinearProgress />}
              <Grid container spacing={2} p={1}>
                <Grid item xs={12} lg={12}>
                  {_shippingConfigs?.generalConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              General
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/logo/logo_orderii_english.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.generalConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {_shippingConfigs?.trConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              Turkey
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/flags/turkey.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.trConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {_shippingConfigs?.usConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              US
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/flags/usa.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.usConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {_shippingConfigs?.agConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              Arabian Golf
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/flags/aeu.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.agConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {_shippingConfigs?.euConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              Europe
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/flags/eu.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.euConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {_shippingConfigs?.wsmConfiguration ? (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <CardHeader
                          title={
                            <Typography variant="h4" component="div">
                              Whole Sale Market
                            </Typography>
                          }
                          avatar={
                            <Box
                              component="img"
                              src="/static/images/flags/wsm.png"
                              width={30}
                            />
                          }
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataGrid
                          sx={{
                            ".MuiDataGrid-cell": {
                              justifyContent: "center",
                            },
                          }}
                          autoHeight
                          rows={_shippingConfigs?.wsmConfiguration}
                          columns={columns}
                          getRowId={(params) => params.id}
                          rowHeight={57}
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          components={{
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
