import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  deleteLocalShippingCompany,
  getAllLocalShippingCompany,
  getById,
  openDialog,
  resetForm,
  savePageNo,
  saveSearchBody,
} from "src/actions/localShippingCompany";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "src/components/CustomDialog";
import { MdDeleteForever } from "react-icons/md";
import useCanShow from "src/components/CanShow";
import moment from "moment";

export default function LocalShippingCompanyTable() {
  const dispatch = useDispatch();

  const updateRole = useCanShow("25-02");
  const deleteRole = useCanShow("25-03");

  const _LocalShippingCompanyForm = useSelector(
    (state) => state.localShippingCompany
  );
  const _LocalShippingCompany = useSelector(
    (state) => state.localShippingCompany.localShippingCompanyData
  );
  const loading = useSelector((state) => state.localShippingCompany.reload);
  const pageNo = useSelector((state) => state.localShippingCompany.pageNo);

  const [page, setPage] = useState(pageNo);
  const searchBody = useSelector(
    (state) => state.localShippingCompany.searchBody
  );
  const [searchName, setSearchName] = useState(searchBody.name);
  const [phoneNumber, setPhoneNumber] = useState(searchBody.phoneNumber);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    dispatch(
      getAllLocalShippingCompany(page + 1, {
        ...searchBody,
      })
    );
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(savePageNo(newPage));
    setPage(newPage);
  };

  function deleteSelectedGiftsBrand(id) {
    dispatch(
      deleteLocalShippingCompany(id, page + 1, {
        ...searchBody,
        isPublicEvent: true,
      })
    ).then((x) => {
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "phone",
      flex: 1,
    },
    {
      field: "address",
      headerName: "address",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => moment(params.row.createdAt).format("YYYY-MM-DD"),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(getById(params.row.id)).then(() => {
              dispatch(openDialog());
            });
          }}
        />,
        <GridActionsCellItem
          icon={<MdDeleteForever size={25} color="red" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          disabled={params.row.itemsCount > 0}
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() =>
              deleteSelectedGiftsBrand(_LocalShippingCompanyForm.form?.id)
            }
            selectedObject={`${_LocalShippingCompanyForm.form?.name ?? ""}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/^0+/, ""));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  setPhoneNumber("");
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      name: "",
                      phone: "",
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 410px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _LocalShippingCompany?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_LocalShippingCompany?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
