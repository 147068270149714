import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  TextField,
  Box,
  LinearProgress,
  CardHeader,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../../../components/CustomSpinner";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import TransferMoney from "./TransferMoney";
import { getById, update, getOrderiiBalance } from "src/actions/wallet";
import {
  wallet_action_types,
  Transaction_Status,
  country_codes,
} from "src/utils/common";
import { getAllTransaction } from "src/actions/transaction";
import TollIcon from "@mui/icons-material/Toll";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InfoIcon from "@mui/icons-material/Info";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import RolePermission from "src/components/RolePermission";
import DateRangepicker from "src/components/DateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";
import { Helmet } from "react-helmet";
import { BsWallet2 } from "react-icons/bs";

const Wallet = () => {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const scrollToTop = useRef(null);

  const [rowCount, setRowCount] = useState();

  const [transactions, setTransactions] = useState(null);

  const [orderiiBalance, setOrderiiBalance] = useState({
    cash: 0,
    wallet: 0,
  });

  const [refresh, setRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [openChangeWalletAmount, setOpenChangeWalletAmount] = useState(false);
  const [actionType, setActionType] = useState("");

  const userId = window.location.pathname.replace("/app/wallet/", "");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [transactionFilter, setTransactionFilter] = useState({
    startDate: moment("2020-01-01").format("yyyy-MM-DD 00:00:00"),
    endDate: null,
    transactionType: null,
    transactionStatus: null,
  });

  useEffect(() => {
    setReload(true);
    dispatch(
      getAllTransaction(userId, page + 1, { ...transactionFilter, pageSize })
    ).then((res) => {
      setTransactions(res.data);
      setRowCount(res.rowCount);
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, pageSize]);

  useEffect(() => {
    if (_UserInfo.roleOEndpoint.map((data) => data.Code).includes("34-10")) {
      dispatch(getOrderiiBalance()).then((res) => {
        setOrderiiBalance(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getWalletByUserId(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  function getWalletByUserId(userId) {
    dispatch(getById(userId))
      .then((res) => {
        setWallet(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401)
            dispatch(showErrorSnackbar("Token expire"));
          //un-authorized ,Logout
          else if (err.response.status === 400)
            dispatch(showErrorSnackbar(err.response.data.message));
        } else dispatch(showErrorSnackbar("Network error"));
      });
  }

  const [wallet, setWallet] = useState({
    address: "null",
    balance: 0,
    id: null,
    name: "",
    phoneNumber: "",
    points: 0,
    walletType: 0,
  });

  const dispatch = useDispatch();

  function updateWallet() {
    setIsLoading(true);
    dispatch(
      update({
        walletId: wallet.id,
        type: wallet.walletType,
      })
    )
      .then((res) => {
        dispatch(showSuccessSnackbar("Type updated successfully"));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function CustomToolbar() {
    return (
      <Box>
        <GridToolbarContainer>
          <GridToolbarExport
            printOptions={{
              disableToolbarButton: true,
              hideFooter: true,
              hideToolbar: true,
            }}
            csvOptions={{
              fileName: "Transactions || Orderii",
              utf8WithBom: true,
              allColumns: true,
            }}
          />
        </GridToolbarContainer>
      </Box>
    );
  }

  const columns = [
    {
      field: "transactionNumber",
      headerName: "T-Number",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "amount",
      headerName: "Amount ($)",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "from",
      headerName: "From",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          {params.row.from === null && params.row.type === 1 ? (
            <Typography color="primary">Orderii Wallet</Typography>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: params.row.from.name.length > 18 ? 14 : null,
                }}
              >
                {params.row.from ? params.row.from.name : null}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactCountryFlag
                  style={{
                    width: "40px",
                    height: "30px",
                    border: "solid 1px #dedede",
                  }}
                  svg
                  countryCode={
                    country_codes.filter(
                      (code) => code.dial_code === params.row.countryCode
                    )[0]?.code
                  }
                />
                <Typography variant="body">{params.row.phoneNumber}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.from?.name || ""} - ${
          `(${params.row.from?.countryCode ?? ""}) ` +
            params.row.from?.phoneNumber || ""
        }`,
    },
    {
      field: "to",
      headerName: "To",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          {params.row.to ? (
            <>
              <Typography
                sx={{ fontSize: params.row.to.name.length > 18 ? 14 : null }}
              >
                {params.row.to.name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactCountryFlag
                  style={{
                    width: "40px",
                    height: "30px",
                    border: "solid 1px #dedede",
                  }}
                  svg
                  countryCode={
                    country_codes.filter(
                      (code) => code.dial_code === params.row.to?.countryCode
                    )[0]?.code
                  }
                />
                <Typography variant="body">
                  {params.row.to?.phoneNumber}
                </Typography>
              </Stack>
            </>
          ) : null}
        </Stack>
      ),
      valueGetter: (params) =>
        `${params.row.to?.name || ""} - ${params.row.to?.phoneNumber || ""}`,
    },
    {
      field: "fromWallet",
      headerName: "From User Balance ($)",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 14 }}>
            {params.row.fromOldBalance !== null &&
            params.row.fromOldBalance !== undefined
              ? `Old: ${params.row.fromOldBalance}`
              : ""}{" "}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {params.row.fromNewBalance !== null &&
            params.row.fromNewBalance !== undefined
              ? `New: ${params.row.fromNewBalance}`
              : ""}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) =>
        `${`Old: ${params.row.fromOldBalance ?? 0}`} - ${`New: ${
          params.row.fromNewBalance ?? 0
        }`}`,
    },
    {
      field: "toWallet",
      headerName: "To User Balance ($)",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 14 }}>
            {params.row.toOldBalance !== null &&
            params.row.toOldBalance !== undefined
              ? `Old: ${params.row.toOldBalance}`
              : ""}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            {params.row.toNewBalance !== null &&
            params.row.toNewBalance !== undefined
              ? `New: ${params.row.toNewBalance}`
              : ""}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) =>
        `${`Old: ${params.row.toOldBalance ?? 0}`} - ${`New: ${
          params.row.toNewBalance ?? 0
        }`}`,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      flex: 0.7,
      renderCell: (params) => (
        <Box>
          {params.row.createdAt ? (
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Typography>
                {moment(params.row.createdAt).format("yyy-MM-DD")}
              </Typography>
              <Typography>
                {moment(params.row.createdAt).format("hh:mm a")}
              </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      flex: 0.6,
      renderCell: (params) => (
        <Box>
          {params.row.type === 1 ? (
            <Typography color="green">DEPOSIT</Typography>
          ) : params.row.type === 2 ? (
            <Typography color="red">WITHDRAW</Typography>
          ) : null}
        </Box>
      ),
      valueGetter: (params) =>
        `${
          params.row.type === 1
            ? "DEPOSIT"
            : params.row.type === 2
            ? "WITHDRAW"
            : ""
        }`,
    },
    {
      field: "exchangeRate",
      headerName: "Exchange Rate ($)",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Typography>{params.row.exchangeRate?.toLocaleString()}</Typography>
      ),
    },
    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ whiteSpace: "break-spaces" }}>
              {params.row.reasonString ? params.row.reasonString : ""}
            </Typography>
            <Typography>
              {params.row.ePaymentTypeString
                ? params.row.ePaymentTypeString
                : null}
            </Typography>
          </Stack>
          {params.row.info !== null ? (
            <Tooltip
              title={
                <Typography>
                  {params.row.info ? params.row.info : null}
                </Typography>
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.note ? (
            <Tooltip
              title={
                <Typography>
                  {params.row.note ? params.row.note : null}
                </Typography>
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {params.row.orderId !== null ? (
            <Tooltip title="order details">
              <IconButton
                href={`/app/order/${
                  params.row.orderId ? params.row.orderId : ""
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <FactCheckIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : params.row.giftOrderId !== null ? (
            <Tooltip title="order details">
              <IconButton
                href={`/app/gifts/order/${
                  params.row.giftOrderId ? params.row.giftOrderId : ""
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <FactCheckIcon sx={{ color: "orange" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      ),
      valueGetter: (params) => params.row.reasonString,
    },
    {
      field: "statusString",
      headerName: "Status",
      headerAlign: "center",
      flex: 0.8,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Wallet | Orderii</title>
      </Helmet>
      <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Card>
              <CardHeader
                title={
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-around"
                    sx={{ marginRight: "10px" }}
                  >
                    <Typography variant="h4" component="div">
                      Wallet
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        fontSize: "25px",
                        width: "100%",
                      }}
                      justifyContent="center"
                    >
                      <Chip
                        label={
                          <Typography>
                            Orderii Wallet:{" "}
                            {orderiiBalance.wallet.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            $
                          </Typography>
                        }
                        size="small"
                        color="warning"
                        variant="outlined"
                      />
                      <Chip
                        label={
                          <Typography>
                            Orderii Cash:{" "}
                            {orderiiBalance.cash.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            $
                          </Typography>
                        }
                        size="small"
                        color="success"
                        variant="outlined"
                      />
                      <Chip
                        label={
                          <Typography>
                            Customer Balance:{" "}
                            {wallet.balance.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            $
                          </Typography>
                        }
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                }
                avatar={<BsWallet2 size={20} />}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
            <Card ref={scrollToTop}>
              <TransferMoney
                open={openChangeWalletAmount}
                handleClose={() => setOpenChangeWalletAmount(false)}
                userName={wallet.name}
                userPhoneNumber={wallet.phoneNumber}
                userBalance={wallet.balance}
                type={actionType}
                onSuccess={(msg) => {
                  setRefresh(!refresh);
                }}
              />

              <Box
                sx={{
                  padding: 2.5,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h4">
                      {wallet.name}{" "}
                      <UserTierMenu
                        userId={wallet?.userId}
                        tier={wallet?.customerTier}
                        onSuccess={() => setRefresh(!refresh)}
                      />
                    </Typography>
                    <Typography variant="h5">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <ReactCountryFlag
                          style={{
                            width: "40px",
                            height: "30px",
                            border: "solid 1px #dedede",
                          }}
                          svg
                          countryCode={
                            country_codes.filter(
                              (code) => code.dial_code === wallet.countryCode
                            )[0]?.code
                          }
                        />
                        <Typography variant="body">
                          {wallet.phoneNumber}
                        </Typography>
                      </Stack>
                    </Typography>
                    <Typography variant="h5">{wallet.address}</Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Wallet type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={wallet.walletType}
                        onChange={(e) =>
                          setWallet({ ...wallet, walletType: e.target.value })
                        }
                      >
                        <FormControlLabel
                          disabled={
                            _UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("34-14")
                              ? false
                              : true
                          }
                          value={0}
                          control={<Radio />}
                          label="Customer" //0
                        />
                        <FormControlLabel
                          disabled={
                            _UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("34-14")
                              ? false
                              : true
                          }
                          value={1}
                          control={<Radio />}
                          label="Representative" //1
                        />
                        <RolePermission permission="34-14">
                          <LoadingButton
                            variant="contained"
                            size="small"
                            onClick={updateWallet}
                            loading={isLoading}
                          >
                            Update
                          </LoadingButton>
                        </RolePermission>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12}>
                        <RolePermission permission="34-01">
                          <Button
                            fullWidth
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setActionType(wallet_action_types.transferMoney);
                              setOpenChangeWalletAmount(true);
                            }}
                          >
                            Transfer Money
                          </Button>
                        </RolePermission>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RolePermission permission="34-08">
                          <Button
                            size="small"
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                              setActionType(wallet_action_types.clearance);
                              setOpenChangeWalletAmount(true);
                            }}
                          >
                            clearance
                          </Button>
                        </RolePermission>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RolePermission permission="34-07">
                          <Button
                            size="small"
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                              setActionType(wallet_action_types.TopUp);
                              setOpenChangeWalletAmount(true);
                            }}
                          >
                            Top-up
                          </Button>
                        </RolePermission>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RolePermission permission="34-09">
                          <Button
                            size="small"
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                              setActionType(wallet_action_types.refund);
                              setOpenChangeWalletAmount(true);
                            }}
                          >
                            Refund
                          </Button>
                        </RolePermission>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RolePermission permission="34-02">
                          <Button
                            size="small"
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                              setActionType(wallet_action_types.withdraw);
                              setOpenChangeWalletAmount(true);
                            }}
                          >
                            Withdraw
                          </Button>
                        </RolePermission>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Stack
                sx={{ padding: 2 }}
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <DateRangepicker
                  startDate={transactionFilter.startDate}
                  endDate={transactionFilter.endDate}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      fromDate: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD 00:00:00"
                      ),
                      toDate: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD 23:59:59"
                      ),
                    };
                    if (
                      updatedFilter.fromDate !== transactionFilter.startDate ||
                      updatedFilter.toDate !== transactionFilter.endDate
                    ) {
                      setTransactionFilter({
                        ...transactionFilter,
                        startDate: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD 00:00:00"
                        ),
                        endDate: moment(searchDate[0].endDate).format(
                          "YYYY-MM-DD 23:59:59"
                        ),
                      });
                      setRefresh(!refresh);
                    }
                    setPage(0);
                  }}
                />
                <Box width={200}>
                  <Autocomplete
                    options={["Deposit", "Withdraw"]}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Transaction Type" />
                    )}
                    onChange={(event, value) => {
                      setTransactionFilter({
                        ...transactionFilter,
                        transactionType: value ?? null,
                      });
                      setRefresh(!refresh);
                    }}
                    value={transactionFilter.transactionType}
                  />
                </Box>
                <Box width={200}>
                  <Autocomplete
                    options={Transaction_Status}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Transaction Status" />
                    )}
                    onChange={(event, value) => {
                      setTransactionFilter({
                        ...transactionFilter,
                        transactionStatus: value ? value.id : null,
                      });
                      setRefresh(!refresh);
                    }}
                    value={
                      Transaction_Status.filter(
                        (x) => x.id === transactionFilter.transactionStatus
                      )[0] ?? null
                    }
                  />
                </Box>
                <Tooltip title="Clear filter">
                  <IconButton
                    onClick={() => {
                      setTransactionFilter({
                        startDate: moment("2020-01-01").format(
                          "yyyy-MM-DD 00:00:00"
                        ),
                        endDate: null,
                        transactionType: null,
                        transactionStatus: null,
                      });
                      setRefresh(!refresh);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Divider />
              <PerfectScrollbar>
                <Box>
                  {reload || !transactions ? (
                    <CustomSpinner />
                  ) : (
                    <Box>
                      <DataGrid
                        sx={{
                          ".MuiDataGrid-cell": {
                            justifyContent: "center",
                          },
                        }}
                        autoHeight
                        rows={transactions}
                        columns={columns}
                        loading={reload}
                        rowHeight={90}
                        disableSelectionOnClick
                        pagination
                        onPageChange={(newPage) => {
                          setPage(newPage);
                          setRefresh(!refresh);
                        }}
                        onPageSizeChange={(newPageSize) => {
                          setPageSize(newPageSize);
                        }}
                        pageSize={pageSize}
                        page={page}
                        rowCount={rowCount ? rowCount : 0}
                        paginationMode="server"
                        rowsPerPageOptions={[20, 50, 75, 100]}
                        components={{
                          LoadingOverlay: LinearProgress,
                          // NoRowsOverlay: CustomEmpty,
                          Toolbar: CustomToolbar,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </PerfectScrollbar>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Wallet;
