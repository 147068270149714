import axios from "../axios";

class shippingCompanyService {
  // ================== Local Shipping Company ==================
  getAllLocal(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] !== "" &&
          (data[key] !== null) & (data[key] !== undefined)
        ) {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/localShippingCompany/${pageNo}?${params}`);
  }

  getByIdLocal(id) {
    return axios.get(`/localShippingCompany/getById/${id}`);
  }

  getListLocal() {
    return axios.get(`/localShippingCompany`);
  }

  createLocal(data) {
    return axios.post(`/localShippingCompany`, data);
  }

  updateLocal(id, data) {
    return axios.put(`/localShippingCompany/${id}`, data);
  }

  deleteLocal(id) {
    return axios.delete(`/localShippingCompany/${id}`);
  }

  // ================== International Shipping Company ==================
  getAllInternational(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] !== "" &&
          (data[key] !== null) & (data[key] !== undefined)
        ) {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/internationalShippingCompany/${pageNo}?${params}`);
  }

  getByIdInternational(id) {
    return axios.get(`/internationalShippingCompany/getById/${id}`);
  }

  getListInternational() {
    return axios.get(`/internationalShippingCompany`);
  }

  createInternational(data) {
    return axios.post(`/internationalShippingCompany`, data);
  }

  updateInternational(id, data) {
    return axios.put(`/internationalShippingCompany/${id}`, data);
  }

  deleteInternational(id) {
    return axios.delete(`/internationalShippingCompany/${id}`);
  }
}
export default new shippingCompanyService();
