import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Stack,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Chip,
  TextField,
  Icon,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  getById,
  getByIdForUpdate,
  resetForm,
  savePromoPageNum,
  getAllBanners,
  deleteBanner,
  pageSizeBanners,
  createBanner,
  saveBannersSearchBody,
} from "src/actions/banners";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { HiDocumentDuplicate } from "react-icons/hi2";
import host from "src/api/host";
import { BANNERS_TYPES } from "src/utils/common";
import useCanShow from "src/components/CanShow";

export default function BannersTable() {
  const dispatch = useDispatch();

  const editBannerRole = useCanShow("02-04");
  const duplicateBannerRole = useCanShow("02-01");
  const deleteBannerRole = useCanShow("02-04");

  const _Banners = useSelector((state) => state.banners.data);
  const _Banner = useSelector((state) => state.banners.form);
  const loading = useSelector((state) => state.banners.reload);
  const savePromoPageNO = useSelector(
    (state) => state.banners.savePromoPageNum
  );
  const _PageSize = useSelector((state) => state.banners.pageSize);
  const _SearchBody = useSelector((state) => state.banners.searchBody);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [owner, setOwner] = useState(_SearchBody.owner);

  const isExpired = [
    { key: true, name: "True" },
    { key: false, name: "False" },
  ];

  // const isTop = [
  //   { key: true, name: "True" },
  //   { key: false, name: "False" },
  // ];

  const isNewUser = [
    { key: true, name: "True" },
    { key: false, name: "False" },
  ];

  useEffect(() => {
    dispatch(savePromoPageNum(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(pageSizeBanners(pageSize));
  }, [dispatch, pageSize]);

  useEffect(() => {
    dispatch(
      getAllBanners(_SearchBody.type, page + 1, {
        ..._SearchBody,
        pageSize: pageSize,
      })
    );
  }, [page, pageSize, _SearchBody, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(
      deleteBanner(id, _SearchBody.type, savePromoPageNO, _PageSize)
    ).then((x) => {
      dispatch(resetForm());
    });
  }

  var columns = [
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 0.5,
    },
    {
      field: "image",
      headerName: "Image",
      flex: 2,
      renderCell: (params) => (
        <img
          src={host + params.row.url}
          style={{ maxHeight: "200px", maxWidth: "250px" }}
          alt="Error"
        />
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "validUpTo",
      headerName: "Valid Up To",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"column"} spacing={0.5}>
          <Typography
            color={
              moment(params.row.validUpTo).format("YYYY-MM-DD") <
              moment().format("YYYY-MM-DD")
                ? "error"
                : "default"
            }
          >
            {moment(params.row.validUpTo).format("YYYY-MM-DD")}
          </Typography>
          <Typography>
            {moment(params.row.validUpTo).format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD")
              ? "Expired"
              : ""}
          </Typography>
        </Stack>
      ),
    },
    // {
    //   field: "isTop",
    //   headerName: "Is Top !",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Chip
    //       label={params.row.isTop ? "Yes" : "No"}
    //       color={params.row.isTop ? "primary" : "warning"}
    //     />
    //   ),
    // },
    {
      field: "isForNewUser",
      headerName: "Is For New User !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isForNewUser ? "Yes" : "No"}
          color={params.row.isForNewUser ? "primary" : "default"}
        />
      ),
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">edit</Icon>}
          label="Edit Banner"
          hidden={!editBannerRole}
          showInMenu
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row.id, host + params.row.url));
          }}
        />,
        <GridActionsCellItem
          icon={<HiDocumentDuplicate color="warning" />}
          label="Duplicate Banner"
          hidden={!duplicateBannerRole}
          showInMenu
          onClick={(e) => {
            dispatch(
              createBanner(
                {
                  owner: params.row.owner,
                  attacmentId: params.row.attachmentId,
                  sequence: params.row.sequence,
                  descriptionEN: params.row.descriptionEN,
                  descriptionAR: params.row.descriptionAR,
                  titleAR: params.row.titleAR,
                  titleEN: params.row.titleEN,
                  redirectUrl: params.row.redirectUrl,
                  isTop: true,
                  isForNewUser: params.row.isForNewUser,
                  validUpTo: params.row.validUpTo,
                  type: params.row.type,
                  giftItemId: params.row.giftItemId ?? null,
                  giftItemCategoryId: params.row.giftItemCategoryId ?? null,
                },
                _SearchBody.type,
                savePromoPageNO,
                { ..._SearchBody, pageSize: pageSize }
              )
            );
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete Banner"
          hidden={!deleteBannerRole}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(_Banner.id)}
            selectedObject={`${_Banner.titleEN} - ${_Banner.titleAR} Promo`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Autocomplete
              options={BANNERS_TYPES || []}
              getOptionLabel={(option) => option.value}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Type" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveBannersSearchBody({
                    ..._SearchBody,
                    type: value ? value.key : 0,
                  })
                );
              }}
              value={
                BANNERS_TYPES?.filter((x) => x.key === _SearchBody.type)[0] ?? 0
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Search Owner"
              size="small"
              value={owner}
              fullWidth
              onChange={(e) => {
                setOwner(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveBannersSearchBody({
                      ..._SearchBody,
                      owner: e.target.value,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={isExpired}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Expired !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveBannersSearchBody({
                    ..._SearchBody,
                    isExpired: value ? value.key : null,
                  })
                );
              }}
              value={
                isExpired.filter((x) => x.key === _SearchBody.isExpired)[0] ??
                null
              }
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Autocomplete
              options={isTop}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Top !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveBannersSearchBody({
                    ..._SearchBody,
                    isTop: value ? value.key : null,
                  })
                );
              }}
              value={
                isTop.filter((x) => x.key === _SearchBody.isTop)[0] ?? null
              }
            />
          </Grid> */}
          <Grid item xs={2}>
            <Autocomplete
              options={isNewUser}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is For New User !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveBannersSearchBody({
                    ..._SearchBody,
                    isNewUser: value ? value.key : null,
                  })
                );
              }}
              value={
                isNewUser.filter((x) => x.key === _SearchBody.isNewUser)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  dispatch(
                    saveBannersSearchBody({
                      isExpired: null,
                      isTop: null,
                      isNewUser: null,
                      owner: "",
                      type: 0,
                    })
                  );
                  setOwner("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  sx={{
                    ".highlight": {
                      backgroundColor: "#bdbdbd",
                      "&:hover": {
                        backgroundColor: "darkgrey",
                      },
                    },
                  }}
                  columns={columns}
                  rows={_Banners.data || []}
                  getRowClassName={(params) =>
                    moment(params.row.validUpTo).format("YYYY-MM-DD") <
                    moment().format("YYYY-MM-DD")
                      ? "highlight"
                      : null
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={200}
                  pagination
                  paginationMode="server"
                  rowCount={_Banners?.rowCount}
                  pageSize={pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
