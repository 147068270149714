import {
  INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM,
  INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID,
  INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER,
  INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG,
  INTERNATIONAL_SHIPPING_COMPANY_RELOAD,
  INTERNATIONAL_SHIPPING_COMPANY_GET_ALL,
  INTERNATIONAL_SHIPPING_COMPANY_GET_LIST,
  INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    name: "",
    phoneNumber: "",
    address: "",
  },
  form: {
    id: null,
    name: "",
    phoneNumber: "",
    address: "",
  },
  internationalShippingCompanyData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  internationalShippingCompanyList: [],
};

function internationalShippingCompanyReducer(
  internationalShippingCompany = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case INTERNATIONAL_SHIPPING_COMPANY_GET_ALL:
      internationalShippingCompany.internationalShippingCompanyData.data =
        payload.data;
      internationalShippingCompany.internationalShippingCompanyData.total =
        payload.pageCount;
      internationalShippingCompany.internationalShippingCompanyData.rowCount =
        payload.rowCount;
      return internationalShippingCompany;

    case INTERNATIONAL_SHIPPING_COMPANY_GET_LIST:
      internationalShippingCompany.internationalShippingCompanyList = payload;
      return internationalShippingCompany;

    case INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID:
      internationalShippingCompany.form = payload;
      return internationalShippingCompany;

    case INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM:
      internationalShippingCompany.form = initialState.form;
      return internationalShippingCompany;

    case INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER:
      return { ...internationalShippingCompany, pageNo: payload };

    case INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY:
      return { ...internationalShippingCompany, searchBody: payload };

    case INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG:
      return {
        ...internationalShippingCompany,
        openDialog: !internationalShippingCompany.openDialog,
      };

    case INTERNATIONAL_SHIPPING_COMPANY_RELOAD:
      return {
        ...internationalShippingCompany,
        reload: !internationalShippingCompany.reload,
      };

    default:
      return internationalShippingCompany;
  }
}

export default internationalShippingCompanyReducer;
