import {
  INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM,
  INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID,
  INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER,
  INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG,
  INTERNATIONAL_SHIPPING_COMPANY_RELOAD,
  INTERNATIONAL_SHIPPING_COMPANY_GET_ALL,
  INTERNATIONAL_SHIPPING_COMPANY_GET_LIST,
  INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import internationalShippingCompany from "src/api/services/shippingCompany.service";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM,
  });
};

export const reloadInternationalShippingCompany = () => (dispatch) => {
  dispatch({
    type: INTERNATIONAL_SHIPPING_COMPANY_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER,
    payload: data,
  });
};

export const create = (data, pagNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadInternationalShippingCompany());
    const res = await internationalShippingCompany.createInternational(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllInternationalShippingCompany(pagNo, searchBody));
    dispatch(openDialog());
    dispatch(reloadInternationalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadInternationalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllInternationalShippingCompany =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadInternationalShippingCompany());
      const res = await internationalShippingCompany.getAllInternational(
        pageNo,
        searchBody
      );
      dispatch({
        type: INTERNATIONAL_SHIPPING_COMPANY_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadInternationalShippingCompany());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadInternationalShippingCompany());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getInternationalShippingCompanyList = () => async (dispatch) => {
  try {
    dispatch(reloadInternationalShippingCompany());
    const res = await internationalShippingCompany.getListInternational();
    dispatch({
      type: INTERNATIONAL_SHIPPING_COMPANY_GET_LIST,
      payload: res.data.data,
    });
    dispatch(reloadInternationalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadInternationalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateInternationalShippingCompany =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadInternationalShippingCompany());
      const res = await internationalShippingCompany.updateInternational(
        id,
        data
      );
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllInternationalShippingCompany(pageNo, searchBody));
      dispatch(openDialog());
      dispatch(reloadInternationalShippingCompany());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadInternationalShippingCompany());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteInternationalShippingCompany =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await internationalShippingCompany.deleteInternational(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllInternationalShippingCompany(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadInternationalShippingCompany());
    const res = await internationalShippingCompany.getByIdInternational(id);
    dispatch({
      type: INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadInternationalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadInternationalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
