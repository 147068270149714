import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { MdOutlineLocalShipping } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import { Add } from "@mui/icons-material";
import { openDialog } from "src/actions/localShippingCompany";
import { useDispatch, useSelector } from "react-redux";
import LocalShippingCompanyForm from "src/pages/cross/shippingCompany/localShippingCompany/from/Form";

const LocalShippingCompanyHeader = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.localShippingCompany.openDialog);

  return (
    <>
      <Helmet>
        <title> Local Shipping Company | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Local Shipping Company
              </Typography>
            }
            avatar={<MdOutlineLocalShipping size={20} />}
            action={
              <RolePermission permission="51-09">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Add />}
                  onClick={() => dispatch(openDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {dialog ? <LocalShippingCompanyForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default LocalShippingCompanyHeader;
