import { Helmet } from "react-helmet";
import { Box, Card, Tab, Tabs } from "@mui/material";
import React from "react";
import { useState } from "react";
import LocalShippingCompany from "src/pages/cross/shippingCompany/localShippingCompany";
import InternationalShippingCompany from "src/pages/cross/shippingCompany/internationalShippingCompany";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShippingCompany = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Shipping Company | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
          <Card sx={{ mx: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
                variant="fullWidth"
              >
                <Tab label="International Shipping Company" {...a11yProps(0)} />
                <Tab label="Local Shipping Company" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Card>
          <TabPanel value={value} index={0}>
            <InternationalShippingCompany />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LocalShippingCompany />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ShippingCompany;
