import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Stack,
  Switch,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Chip,
  InputAdornment,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import {
  deletePromo,
  getAllPromos,
  changeStatus,
  resetForm,
  savePromoPageNum,
  savePromoSearchBody,
} from "src/actions/promos";
import moment from "moment";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { FaRegCopy } from "react-icons/fa";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import { IoIosInformationCircleOutline } from "react-icons/io";

export default function BusinessPromosTable() {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const _Promos = useSelector((state) => state.promos.data);
  const _Promo = useSelector((state) => state.promos.form);
  const loading = useSelector((state) => state.promos.reload);
  const savePromoPageNO = useSelector((state) => state.promos.savePromoPageNum);
  const _SearchBody = useSelector((state) => state.promos.searchBody);

  const [page, setPage] = useState(0);
  const [searchName, setSearchName] = useState(_SearchBody.name);
  const [company, setCompany] = useState(_SearchBody.company);

  const promo_statuses = [
    { name: "Active", key: true },
    { name: "Deactivate", key: false },
  ];
  const isUsed = [
    { name: "Used", key: true },
    { name: "Not Used", key: false },
  ];

  const isExpired = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  useEffect(() => {
    dispatch(savePromoPageNum(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllPromos(page + 1, { ..._SearchBody, isBusiness: true }));
  }, [page, _SearchBody, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      savePromoSearchBody({
        ..._SearchBody,
        pageSize: newPageSize,
      })
    );
  };

  function changePromoState(id, status) {
    dispatch(
      changeStatus(id, status, savePromoPageNO, {
        ..._SearchBody,
        isBusiness: true,
      })
    );
  }

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(
      deletePromo(id, savePromoPageNO, { ..._SearchBody, isBusiness: true })
    ).then((x) => {
      dispatch(resetForm());
    });
  }

  var columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            cursor: "pointer",
            color:
              params.row.promoCodeType === "Global" ? "#8444FF" : "#00B393",
          }}
          onClick={(e) => {
            navigator.clipboard.writeText(params.row.name);
            dispatch(showSuccessSnackbar("Promo Code Copied"));
          }}
        >
          <FaRegCopy />{" "}
          <span style={{ whiteSpace: "break-spaces", color: "#4C5C76" }}>
            {params.row.name}
          </span>
        </Box>
      ),
    },
    {
      field: "promoCodeType",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Typography
            variant="body"
            sx={{
              color:
                params.row.promoCodeType === "Global" ? "#8444FF" : "#00B393",
            }}
          >
            {params.row.promoCodeType}
          </Typography>
          {params.row.note ? (
            <Tooltip title={<Typography>{params.row.note}</Typography>}>
              <Typography>
                <IoIosInformationCircleOutline size={20} />
              </Typography>
            </Tooltip>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "newUsersOnly",
      headerName: "New Users Only !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.newUsersOnly ? "Yes" : "No"}
          color={params.row.newUsersOnly ? "info" : "default"}
        />
      ),
    },
    {
      field: "isUsed",
      headerName: "is Used",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isUsed ? "Yes" : "No"}
          color={params.row.isUsed ? "info" : "default"}
        />
      ),
    },
    {
      field: "isFreeDelivery",
      headerName: "Discount Type",
      flex: 1,
      renderCell: (params) =>
        params.row.isFreeDelivery ? "Free Delivery" : "Discount",
    },
    {
      field: "discount",
      headerName: "Discount (%)",
      flex: 1,
    },
    {
      field: "discountLimit",
      headerName: "Discount limit",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body"
          sx={{
            color:
              params.row.promoCodeType === "Global" ? "#8444FF" : "#00B393",
          }}
        >
          {params.row.discountLimit +
            " " +
            (params.row.promoCodeType === "Global" ? "$" : "IQD")}
        </Typography>
      ),
    },
    {
      field: "totalDiscount",
      headerName: "Total Discount",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body"
          sx={{
            color:
              params.row.promoCodeType === "Global" ? "#8444FF" : "#00B393",
          }}
        >
          {params.row.totalDiscount +
            " " +
            (params.row.promoCodeType === "Global" ? "$" : "IQD")}
        </Typography>
      ),
    },
    {
      field: "totalOrders",
      headerName: "Total Orders",
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendors",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.promoCodeType === "Global"
            ? params.row.vendorId
              ? params.row.vendorName
              : params.row.region
              ? `All ${params.row.region}`
              : "All"
            : "--"}
        </>
      ),
    },
    {
      field: "validUntil",
      headerName: "Expire Date",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={
            moment(params.row.validUntil).format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD")
              ? "error"
              : "default"
          }
        >
          {moment(params.row.validUntil).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "usersCount",
      headerName: "Users Count",
      flex: 1,
      renderCell: (params) =>
        params.row.usersCount === 0 ? "All users" : params.row.usersCount,
    },
    {
      field: "isCashback",
      headerName: "Is Cashback !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isCashback ? "Yes" : "No"}
          color={params.row.isCashback ? "info" : "default"}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active!",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="(Deactive) | (Active)" aria-label="active_deactive">
          <Switch
            disabled={
              _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "25-06")[0]
                ? false
                : true
            }
            checked={params.row.isActive}
            onChange={(e) =>
              changePromoState(params.row.id, !params.row.isActive)
            }
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(_Promo.id)}
            selectedObject={`${_Promo.name} Promo`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    savePromoSearchBody({
                      ..._SearchBody,
                      name: searchName,
                      company: company,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={promo_statuses}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                dispatch(
                  savePromoSearchBody({
                    ..._SearchBody,
                    name: searchName,
                    company: company,
                    isActive: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                promo_statuses.filter(
                  (x) => x.key === _SearchBody.isActive
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={isExpired}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Expired" />
              )}
              onChange={(event, value) => {
                dispatch(
                  savePromoSearchBody({
                    ..._SearchBody,
                    isExpired: value ? value.key : null,
                    name: searchName,
                    company: company,
                  })
                );
                setPage(0);
              }}
              value={
                isExpired.filter((x) => x.key === _SearchBody.isExpired)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={isUsed}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Used" />
              )}
              onChange={(event, value) => {
                dispatch(
                  savePromoSearchBody({
                    ..._SearchBody,
                    isUsed: value ? value.key : null,
                    name: searchName,
                    company: company,
                  })
                );
                setPage(0);
              }}
              value={
                isUsed.filter((x) => x.key === _SearchBody.isUsed)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Company Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={company}
              onChange={(e) => {
                setCompany(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    savePromoSearchBody({
                      ..._SearchBody,
                      company: company,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "start" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={_SearchBody.isAscending}
                    onChange={(e) => {
                      dispatch(
                        savePromoSearchBody({
                          ..._SearchBody,
                          isAscending: e.target.checked,
                        })
                      );
                      setPage(0);
                    }}
                  />
                }
                label="Sort by created at"
              />
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={_SearchBody.isBusiness}
                    onChange={(e) => {
                      dispatch(
                        savePromoSearchBody({
                          ..._SearchBody,
                          isBusiness: e.target.checked,
                        })
                      );
                      setPage(0);
                    }}
                  />
                }
                label="Is Business"
              /> */}
              <Tooltip title="Clear filter">
                <IconButton
                  onClick={() => {
                    dispatch(
                      savePromoSearchBody({
                        pageSize: 15,
                        isActive: true,
                        isExpired: false,
                        isAscending: false,
                        name: "",
                        company: "",
                      })
                    );
                    setSearchName("");
                    setCompany("");
                    setPage(0);
                  }}
                >
                  <AiOutlineClose />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 350px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                    ".highlight": {
                      backgroundColor: "#ececec",
                      "&:hover": {
                        backgroundColor: "#dedede",
                      },
                    },
                  }}
                  columns={columns}
                  rows={_Promos?.data || []}
                  loading={loading}
                  disableSelectionOnClick
                  getRowClassName={(params) =>
                    moment(params.row.validUntil).format("YYYY-MM-DD") <
                    moment().format("YYYY-MM-DD")
                      ? "highlight"
                      : null
                  }
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_Promos?.rowCount}
                  pageSize={_SearchBody?.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
