import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { saveUsersPageNum, saveUsersSearchBody } from "src/actions/users";
import {
  customer_loyalty_tiers,
  customer_tiers,
  user_role,
  wallet_Types,
} from "src/utils/common";
import RolePermission from "src/components/RolePermission";

export default function UsersFilter({ pageNo = function () {} }) {
  const dispatch = useDispatch();

  const saveUsersPageNo = useSelector(
    (state) => state.users.saveUsersPageNum ?? 0
  );

  const _searchBody = useSelector((state) => state.users.searchUsersBody);
  const [searchBody, setSearchBody] = useState(_searchBody);

  useEffect(() => {
    dispatch(saveUsersPageNum(saveUsersPageNo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveUsersPageNo]);

  const isActive = [
    { name: "Active", key: true },
    { name: "Inactive", key: false },
  ];

  return !_searchBody ? null : (
    <Grid container spacing={2} paddingTop={2} paddingX={2}>
      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          fullWidth
          placeholder="Search by phone"
          variant="outlined"
          type="number"
          value={searchBody.phoneNumber}
          sx={{ marginRight: 1 }}
          onChange={(e) => {
            var phoneNumber = e.target.value;
            setSearchBody({
              ...searchBody,
              phoneNumber:
                phoneNumber[0] === "0" ? phoneNumber.substring(1) : phoneNumber,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              dispatch(saveUsersSearchBody(searchBody));
              pageNo(0);
            }
          }}
        />
      </Grid>
      <RolePermission permission="31-10">
        <Grid item xs={12} md={2}>
          <TextField
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search by name"
            variant="outlined"
            value={searchBody.fullName}
            onChange={(e) => {
              setSearchBody({
                ...searchBody,
                fullName: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(saveUsersSearchBody(searchBody));
                pageNo(0);
              }
            }}
          />
        </Grid>
      </RolePermission>
      <RolePermission permission="31-10">
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={user_role}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => <TextField {...params} label="Role" />}
            onChange={(event, value) => {
              dispatch(
                saveUsersSearchBody({
                  ...searchBody,
                  role: value ? value.id : 0,
                })
              );
              setSearchBody({
                ...searchBody,
                role: value ? value.id : 0,
              });
              pageNo(0);
            }}
            value={
              user_role.filter((x) => x.id === _searchBody.role)[0] ?? null
            }
          />
        </Grid>
      </RolePermission>
      <RolePermission permission="31-10">
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={wallet_Types}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Wallet Type" />
            )}
            onChange={(event, value) => {
              dispatch(
                saveUsersSearchBody({
                  ...searchBody,
                  walletType: value ? value.id : null,
                })
              );
              setSearchBody({
                ...searchBody,
                walletType: value ? value.id : null,
              });
              pageNo(0);
            }}
            value={
              wallet_Types.filter((x) => x.id === _searchBody.walletType)[0]
                ? wallet_Types.filter((x) => x.id === _searchBody.walletType)[0]
                : null
            }
          />
        </Grid>
      </RolePermission>
      <RolePermission permission="31-10">
        <Grid item xs={12} md={2}>
          <Autocomplete
            options={isActive}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(event, value) => {
              setSearchBody({
                ...searchBody,
                isActive: value ? value.key : null,
              });
              dispatch(
                saveUsersSearchBody({
                  ...searchBody,
                  isActive: value ? value.key : null,
                })
              );
              pageNo(0);
            }}
            value={
              isActive.filter((x) => x.key === _searchBody.isActive)[0] ?? null
            }
          />
        </Grid>
      </RolePermission>
      <Grid item xs={12} md={2}>
        <Autocomplete
          options={customer_tiers}
          getOptionLabel={(option) => option.name}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Customer Tiers" />
          )}
          onChange={(event, value) => {
            setSearchBody({
              ...searchBody,
              customerTier: value ? value.id : null,
            });
            dispatch(
              saveUsersSearchBody({
                ...searchBody,
                customerTier: value ? value.id : null,
              })
            );
            pageNo(0);
          }}
          value={
            customer_tiers.filter(
              (x) => x.id === _searchBody.customerTier
            )[0] ?? null
          }
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Autocomplete
          options={customer_loyalty_tiers}
          getOptionLabel={(option) => option.name}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Loyalty Tiers" />
          )}
          onChange={(event, value) => {
            setSearchBody({
              ...searchBody,
              customerLoyaltyTier: value ? value.id : null,
            });
            dispatch(
              saveUsersSearchBody({
                ...searchBody,
                customerLoyaltyTier: value ? value.id : null,
              })
            );
            pageNo(0);
          }}
          value={
            customer_loyalty_tiers.filter(
              (x) => x.id === _searchBody.customerLoyaltyTier
            )[0] ?? null
          }
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder="Search by User Id"
          variant="outlined"
          value={searchBody.id}
          onChange={(e) => {
            setSearchBody({
              ...searchBody,
              id: e.target.value,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              dispatch(saveUsersSearchBody(searchBody));
              pageNo(0);
            }
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <Tooltip title="Search with filter">
            <IconButton
              onClick={() => {
                dispatch(
                  saveUsersSearchBody({
                    ..._searchBody,
                    ...searchBody,
                    pageSize: _searchBody.pageSize,
                  })
                );
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear filter">
            <IconButton
              onClick={() => {
                dispatch(
                  saveUsersSearchBody({
                    phoneNumber: "",
                    fullName: "",
                    role: 0,
                    walletType: null,
                    isActive: null,
                    pageSize: 15,
                    customerTier: null,
                    customerLoyaltyTier: null,
                  })
                );
                setSearchBody({
                  phoneNumber: "",
                  fullName: "",
                  role: 0,
                  walletType: null,
                  isActive: null,
                  pageSize: 15,
                  customerTier: null,
                  customerLoyaltyTier: null,
                });
                pageNo(0);
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
}
