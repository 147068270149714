import moment from "moment";
import {
  CREATE_USER,
  GET_ALL_USERS,
  UPDATE_USER,
  CHANGE_STATUS,
  SAVE_USERS_PAGE_NUM,
  SAVE_SEARCH_BODY_USERS,
  OPEN_USERS_DEALOG,
  GET_BY_ID_USERS,
  RESET_FORM_USERS,
  USER_INFO,
  SIDEBAR_DASHBOARD,
  USER_STATUS,
  RELOAD_USER_INFO,
  RELOAD_USER_GET_BY_TOKEN,
  OPEN_NOTIFY_USERS_DIALOG,
  FILTERS_TYPE,
  OPEN_PHONE_CODE_DIALOG,
  OPEN_PHONE_CODE_DATA,
  LOADING_USERS,
  OPEN_DEVICE_INFO_DIALOG,
} from "../actions/type";

const initialState = {
  reload: false,
  loading: false,
  openDialog: false,
  openStatusDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 10,
  searchUsersBody: {
    phoneNumber: "",
    fullName: "",
    role: 0,
    walletType: null,
    isActive: null,
    pageSize: 15,
    customerTier: null,
    customerLoyaltyTier: null,
  },
  saveUsersPageNum: 0,
  form: {
    id: null,
    addressId: null,
    gender: null,
    isActive: false,
    birthDate: moment().format("YYYY-MM-DD"),
    firstName: "",
    secondName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
    userDeactivationReasonEN: "",
    userDeactivationReasonAR: "",
    userDeactivationReasonId: null,
  },
  data: [],
  getById: {},
  userInfoReload: false,
  userInfo: {
    isLoggedIn: false,
    id: null,
    addressId: null,
    gender: null,
    isActive: false,
    birthDate: moment().format("YYYY-MM-DD"),
    firstName: "",
    secondName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
    roles: [],
    roleOEndpoint: [],
    serviceType: "",
    customerTierName: "",
    customerTier: null,
  },
  sidebarDashboard: JSON.parse(localStorage.getItem("collapseSidebar")),
  notifyUserDialog: false,
  filterType: localStorage.getItem("filterType"),
  phoneCode: {
    dialog: false,
    data: [],
  },
  deviceInfo: { dialog: false, data: "" },
};

function userReducer(users = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER:
      return [...users, payload];

    case GET_ALL_USERS:
      users.data = payload.data;
      users.pageCount = payload.pageCount;
      users.rowCount = payload.rowCount;
      return users;

    case GET_BY_ID_USERS:
      users.form.id = payload.id;
      users.form.addressId = payload.cityId;
      users.form.gender = payload.gender;
      users.form.isActive = payload.isActive;
      users.form.firstName = payload.firstName;
      users.form.secondName = payload.secondName;
      users.form.email = payload.email;
      users.form.phoneNumber = payload.phoneNumber;
      users.form.password = payload.password;
      users.form.lastName = payload.lastName;
      users.form.provinceId = payload.provinceId;
      users.form.birthDate = payload.birthDate;
      users.form.role = payload.role;
      users.form.userDeactivationReasonEN = payload.userDeactivationReasonEN;
      users.form.userDeactivationReasonAR = payload.userDeactivationReasonAR;
      users.form.userDeactivationReasonId = payload.userDeactivationReasonId;
      return users;

    case USER_INFO:
      const flattened = payload.roleOEndpoint.flat();

      const uniqueAndNonNull = Array.from(
        new Set(flattened.filter((item) => item !== null))
      );
      users.userInfo.isLoggedIn = true;
      users.userInfo.id = payload.id;
      users.userInfo.addressId = payload.cityId;
      users.userInfo.gender = payload.gender;
      users.userInfo.isActive = payload.isActive;
      users.userInfo.firstName = payload.firstName;
      users.userInfo.secondName = payload.secondName;
      users.userInfo.email = payload.email;
      users.userInfo.phoneNumber = payload.phoneNumber;
      users.userInfo.password = payload.password;
      users.userInfo.lastName = payload.lastName;
      users.userInfo.provinceId = payload.provinceId;
      users.userInfo.cityId = payload.cityId;
      users.userInfo.birthDate = payload.birthDate;
      users.userInfo.role = payload.role;
      users.userInfo.roles = payload.userRoles;
      users.userInfo.roleOEndpoint = uniqueAndNonNull;
      users.userInfo.serviceType = payload.serviceType;
      users.userInfo.customerTierName = payload.customerTierName;
      users.userInfo.customerTier = payload.customerTier;
      return users;

    case RESET_FORM_USERS:
      users.form.id = null;
      users.form.addressId = null;
      users.form.gender = null;
      users.form.provinceId = null;
      users.form.isActive = false;
      users.form.firstName = "";
      users.form.secondName = "";
      users.form.lastName = "";
      users.form.email = "";
      users.form.phoneNumber = "";
      users.form.password = "";
      users.form.birthDate = moment().format("YYYY-MM-DD");
      return users;

    case SAVE_SEARCH_BODY_USERS:
      return { ...users, searchUsersBody: payload };

    case SAVE_USERS_PAGE_NUM:
      users.saveUsersPageNum = payload;
      return users;

    case OPEN_USERS_DEALOG:
      return { ...users, openDialog: !payload };

    case OPEN_NOTIFY_USERS_DIALOG:
      return { ...users, notifyUserDialog: !users.notifyUserDialog };

    case USER_STATUS:
      return { ...users, openStatusDialog: !users.openStatusDialog };

    case SIDEBAR_DASHBOARD:
      localStorage.setItem("collapseSidebar", payload);
      return { ...users, sidebarDashboard: payload };

    case UPDATE_USER:
      var indexToChange = users.data.indexOf(
        users.data.filter((x) => x.id === payload.id)[0]
      );
      users.data.splice(indexToChange, 1, payload);
      return users;

    case RELOAD_USER_INFO:
      return { ...users, reload: !users.reload };

    case LOADING_USERS:
      return { ...users, loading: !users.loading };

    case RELOAD_USER_GET_BY_TOKEN:
      return { ...users, userInfoReload: !users.userInfoReload };

    case CHANGE_STATUS:
      var newData = users.data;
      newData.map((user) => {
        if (user.id === payload.id) {
          user.isActive = payload.status;
        }
        return user;
      });
      return users;

    case FILTERS_TYPE:
      localStorage.setItem("filterType", payload);
      return { ...users, filterType: payload };

    case OPEN_PHONE_CODE_DATA:
      users.phoneCode.data = payload;
      return users;

    case OPEN_PHONE_CODE_DIALOG:
      return {
        ...users,
        phoneCode: {
          dialog: !users.phoneCode.dialog,
          data: users.phoneCode.data,
        },
      };

    case OPEN_DEVICE_INFO_DIALOG:
      // const convertToJsonString = (str) => {
      //   return str
      //     .replace(/([{,])\s*([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Add quotes around keys
      //     .replace(/:\s*([^,{}[\]]+)/g, (match, p1) => {
      //       // Add quotes around values, but exclude date-time values and nested structures
      //       if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [A-Z]+$/.test(p1)) {
      //         return `: ${p1}`;
      //       }
      //       // Exclude nested objects and arrays from being quoted
      //       if (/^{.*}$/.test(p1) || /^\[.*\]$/.test(p1)) {
      //         return `: ${p1}`;
      //       }
      //       return `: "${p1}"`;
      //     })
      //     .replace(/; /g, ", ") // Replace semicolon with comma
      //     .replace(/"([^"]+)-([^"]+)"/g, '"$1-$2"') // Fix hyphenated strings
      //     .replace(/,(\s*})/g, "$1") // Remove trailing commas before closing braces
      //     .replace(/"(\d+),(\d+)"/g, '"$1.$2"') // Handle decimal numbers
      //     .replace(/"(\d+)\s*:\s*(\d+)"/g, '"$1:$2"'); // Handle colons within values
      // };

      return {
        ...users,
        deviceInfo: {
          dialog: !users.deviceInfo.dialog,
          data: payload ? payload : "",
        },
      };
    default:
      return users;
  }
}

export default userReducer;
