import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  Chip,
  Autocomplete,
  Typography,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllUserAddresses,
  saveUserAddressesPageNum,
  saveUserAddressesSearchBody,
} from "src/actions/gifts/userAddresses";
import CloseIcon from "@mui/icons-material/Close";
import { getAllAddresses } from "src/actions/addresses";
import ReactCountryFlag from "react-country-flag";
import { country_codes } from "src/utils/common";

export default function UserAddressesTable() {
  const dispatch = useDispatch();

  const _UserAddresses = useSelector(
    (state) => state.userAddresses.userAddressesData
  );
  const loading = useSelector((state) => state.userAddresses.reload);
  const pageNo = useSelector((state) => state.userAddresses.pageNo);
  const addresses = useSelector((state) => state.addresses);

  const [page, setPage] = useState(pageNo);
  const searchBody = useSelector((state) => state.userAddresses.searchBody);
  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  const isActive = [
    { name: "Default", key: true },
    { name: "Not Default", key: false },
  ];

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUserAddresses(page + 1, searchBody));
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveUserAddressesPageNum(newPage));
    setPage(newPage);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      renderCell: (params) => params.row.user?.name,
    },
    // {
    //   field: "currentCountry",
    //   headerName: "Creator Current Country",
    //   flex: 1,
    //   renderCell: (params) => params.row.user?.currentCountry,
    // },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <ReactCountryFlag
            style={{
              width: "40px",
              height: "30px",
              border: "solid 1px #dedede",
            }}
            svg
            countryCode={
              country_codes.filter(
                (code) => code.dial_code === params.row.user?.countryCode
              )[0]?.code
            }
          />
          <Typography variant="body">{params.row.user?.phoneNumber}</Typography>
        </Stack>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: (params) =>
        params.row.city?.province?.nameAR + ", " + params.row.city?.nameAR,
    },
    {
      field: "neighborhood",
      headerName: "Neighborhood",
      flex: 1,
    },
    {
      field: "nearestLocation",
      headerName: "Nearest Location",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Default Address",
      flex: 1,
      renderCell: (params) =>
        params.row.isActive ? (
          <Chip variant="outlined" color="success" label={"Yes"} size="small" />
        ) : (
          <Chip
            variant="outlined"
            color="secondary"
            label={"No"}
            size="small"
          />
        ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Autocomplete
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Default Address !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveUserAddressesSearchBody({
                    ...searchBody,
                    isActive: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                isActive.filter((x) => x.key === searchBody.isActive)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              options={addresses}
              getOptionLabel={(option) =>
                option.nameAR
                  ? `${option.nameAR} , ${option.nameUS}`
                  : "Not selected"
              }
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Provinces" />
              )}
              onChange={(event, value) => {
                setSelectedProvince(
                  value ? value : { nameAR: "", nameUS: "", cities: [] }
                );
                dispatch(
                  saveUserAddressesSearchBody({
                    ...searchBody,
                    provinceId: value ? value.id : null,
                  })
                );
              }}
              value={
                addresses.filter((x) => x.id === searchBody.provinceId)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              options={selectedProvince.cities}
              getOptionLabel={(option) =>
                option ? `${option.nameAR} , ${option.nameUS}` : "Not selected"
              }
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Cities" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveUserAddressesSearchBody({
                    ...searchBody,
                    cityId: value ? value.id : null,
                  })
                );
                setPage(0);
              }}
              value={
                addresses
                  .map((city) => city.cities)
                  .flat()
                  .filter((x) => x.id === searchBody.cityId)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  dispatch(
                    saveUserAddressesSearchBody({
                      ...searchBody,
                      pageSize: 15,
                      cityId: null,
                      provinceId: null,
                      isActive: null,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _UserAddresses?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_UserAddresses?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
