import { DeleteForever } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { GiCardDiscard } from "react-icons/gi";
import { RiVipCrownFill, RiVipFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderItemFromShipment,
  orderItemsExistInShipments,
} from "src/actions/shipments";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import { SHIPMENTS_GET_BY_ID } from "src/actions/type";
import { CustomDialog } from "src/components/CustomDialog";
import RolePermission from "src/components/RolePermission";
import { VENDORS_LOGO } from "src/utils/common";

export default function ExistInShipmentsForm() {
  const dispatch = useDispatch();

  const shipments = useSelector(
    (state) => state.shipments.OrderItemsExistInShipments
  );
  const newShipmentData = useSelector(
    (state) => state.shipments.form?.orderItems
  );
  const reload = useSelector((state) => state.shipments.reload);

  const [items, setItems] = useState(shipments.data ?? []);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});

  useEffect(() => {
    setItems(shipments.data ?? []);
  }, [shipments.data]);

  function handleDeleteOrderItemFromShipment(id) {
    dispatch(
      deleteOrderItemFromShipment(dialogData.shippingNumber?.id, dialogData.id)
    ).then((res) => {
      setOpenDeleteDialog(false);
      setItems(items.filter((item) => item.id !== dialogData.id));
    });
  }

  const columns = [
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
          <img
            alt="vendor logo"
            src={
              params.row.vendorLogo
                ? params.row.vendorLogo
                : VENDORS_LOGO.filter((vendor) =>
                    vendor.name === params.vendorName ? vendor.path : null
                  )[0]?.path
            }
            width={95}
          />
          {params.row.isVipShipped && (
            <Stack direction="column" spacing={-0.8}>
              <RiVipCrownFill size={30} color="#D99B12" />
              <RiVipFill size={30} color="#D99B12" />
            </Stack>
          )}
          {params.shippingOptionNameId != null ? (
            <Chip
              variant="outlined"
              size="small"
              label={params.shippingOptionNameEn}
              color={
                params.shippingOptionNameEn === "Sea"
                  ? "info"
                  : params.shippingOptionNameEn === "Express"
                  ? "warning"
                  : "success"
              }
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "imageUrl",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          sx={{
            height: 130,
            objectFit: "contain",
          }}
          alt="itemImage"
          src={params.value}
        />
      ),
    },
    {
      field: "itemName",
      flex: 2,
      headerName: "Name",
      renderCell: (params) => (
        <Tooltip title={params.row.itemName}>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {params.row.itemName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "itemStatus",
      flex: 1,
      headerName: "Item Status",
      renderCell: (params) => {
        return (
          <Chip
            variant="outlined"
            size="small"
            label={params.row.itemStatusString}
            color={"secondary"}
          />
        );
      },
    },
    { field: "orderiiNumber", flex: 1, headerName: "Orderii Number" },
    {
      field: "shippingNumber",
      flex: 2,
      headerName: "Shipping Number",
      renderCell: (params) =>
        params.row.shippingNumber?.shippingNumber ? (
          <Tooltip title={params.row.shippingNumber?.shippingNumber ?? +"---"}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  params.row.shippingNumber?.shippingNumber
                );
                dispatch(
                  showSuccessSnackbar("Shipment number copied to clipboard")
                );
              }}
            >
              <Typography variant="body" noWrap color="primary">
                <FaRegCopy />{" "}
                {params.row.shippingNumber?.shippingNumber ?? +"---"}
              </Typography>
            </Button>
          </Tooltip>
        ) : null,
    },
    { field: "quantity", flex: 1, headerName: "Quantity" },
    {
      field: "subTotal",
      flex: 1,
      headerName: "Subtotal $",
      renderCell: (params) => (
        <Typography variant="subtitle2">
          {params.row?.subTotal?.toLocaleString() ?? ""}
        </Typography>
      ),
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <Stack spacing={2}>
          <RolePermission permission="59-06">
            <Button
              startIcon={<DeleteForever />}
              color="error"
              aria-label="delete"
              variant="contained"
              onClick={(e) => {
                setOpenDeleteDialog(true);
                setDialogData(params.row);
              }}
            >
              Delete
            </Button>
          </RolePermission>
          <Button
            startIcon={<GiCardDiscard />}
            color="warning"
            variant="contained"
            aria-label="delete"
            onClick={(e) => {
              setItems(items.filter((item) => item.id !== params.row.id));
              dispatch({
                type: SHIPMENTS_GET_BY_ID,
                payload: {
                  shippingNumber: "",
                  itemsCount: newShipmentData?.filter(
                    (item) => item.id !== params.row.id
                  ).length,
                  region: "",
                  orderItems: newShipmentData?.filter(
                    (item) => item.id !== params.row.id
                  ),
                },
              });
            }}
          >
            Discard
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <CustomDialog
      isOpen={shipments.dialog}
      handleClose={() => {
        dispatch(orderItemsExistInShipments([]));
        setItems([]);
      }}
      title={"Order Items Exist In Shipments"}
      maxWidth="xl"
      Draggable={true}
      loading={reload}
      // isRequierdActions
      // isRequierdAdd
      // onAdd={searchItemByQr}
      // onClear={() => {
      //   setItems([]);
      //   setOrderiiNumber("");
      //   setShippingNumber("");
      // }}
    >
      {openDeleteDialog && (
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={() => setOpenDeleteDialog(false)}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          onDelete={() => handleDeleteOrderItemFromShipment(dialogData.id)}
          selectedObject={dialogData.itemName}
          maxWidth="sm"
        ></CustomDialog>
      )}
      {reload ? (
        <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
      ) : null}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {items.length > 0 ? (
              <Box
                sx={{
                  height: { md: "calc(100vh - 460px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  rowHeight={150}
                  rows={items}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  selectionModel={[]}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
}
