import {
  LOCAL_SHIPPING_COMPANY_RESET_FORM,
  LOCAL_SHIPPING_COMPANY_GET_BY_ID,
  LOCAL_SHIPPING_COMPANY_PAGE_NUMBER,
  LOCAL_SHIPPING_COMPANY_OPEN_DIALOG,
  LOCAL_SHIPPING_COMPANY_RELOAD,
  LOCAL_SHIPPING_COMPANY_GET_ALL,
  LOCAL_SHIPPING_COMPANY_GET_LIST,
  LOCAL_SHIPPING_COMPANY_SEARCH_BODY,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import localShippingCompany from "src/api/services/shippingCompany.service";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: LOCAL_SHIPPING_COMPANY_OPEN_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: LOCAL_SHIPPING_COMPANY_RESET_FORM,
  });
};

export const reloadLocalShippingCompany = () => (dispatch) => {
  dispatch({
    type: LOCAL_SHIPPING_COMPANY_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: LOCAL_SHIPPING_COMPANY_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: LOCAL_SHIPPING_COMPANY_PAGE_NUMBER,
    payload: data,
  });
};

export const create = (data, pagNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadLocalShippingCompany());
    const res = await localShippingCompany.createLocal(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllLocalShippingCompany(pagNo, searchBody));
    dispatch(openDialog());
    dispatch(reloadLocalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadLocalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllLocalShippingCompany =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadLocalShippingCompany());
      const res = await localShippingCompany.getAllLocal(pageNo, searchBody);
      dispatch({
        type: LOCAL_SHIPPING_COMPANY_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadLocalShippingCompany());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadLocalShippingCompany());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getLocalShippingCompanyList = () => async (dispatch) => {
  try {
    dispatch(reloadLocalShippingCompany());
    const res = await localShippingCompany.getListLocal();
    dispatch({
      type: LOCAL_SHIPPING_COMPANY_GET_LIST,
      payload: res.data.data,
    });
    dispatch(reloadLocalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadLocalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateLocalShippingCompany =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadLocalShippingCompany());
      const res = await localShippingCompany.updateLocal(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllLocalShippingCompany(pageNo, searchBody));
      dispatch(openDialog());
      dispatch(reloadLocalShippingCompany());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadLocalShippingCompany());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteLocalShippingCompany =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await localShippingCompany.deleteLocal(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllLocalShippingCompany(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadLocalShippingCompany());
    const res = await localShippingCompany.getByIdLocal(id);
    dispatch({
      type: LOCAL_SHIPPING_COMPANY_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadLocalShippingCompany());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadLocalShippingCompany());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
