import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Divider,
  IconButton,
  LinearProgress,
  Chip,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAttachment,
  getTicketById,
  unAssignTicket,
} from "src/actions/ticket/ticket";
import { CustomDialog } from "src/components/CustomDialog";
import { Ticket_Priority, Ticket_Status } from "src/utils/common";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CustomSpinner from "src/components/CustomSpinner";
import TicketStatusMenu from "./Status/Menu";
import TicketTimelineDialog from "./StatusTimeline/menu";
import TicketComment from "./Comment";
import TicketPriorityMenu from "./Priority/Menu";
import { getEmployee } from "src/actions/department/employee";
import AssignessList from "./Assignees/List";
import styled from "@emotion/styled";
import { BsFillXCircleFill } from "react-icons/bs";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router";
import { Visibility } from "@mui/icons-material";
import RolePermission from "src/components/RolePermission";
import DepartmentMenu from "src/pages/cross/department/Manu/Manu";

const StyledAvatar = styled("div")`
  width: 56px;
  height: 56px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export default function TicketDetailsList() {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [openAssigneesDialog, setOpenAssigneesDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [hoveredColor, setHoveredColor] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [showPanZoom, setShowPanZoom] = useState("");

  const ticketDetailsData = useSelector((state) => state.tickets.ticketDetails);
  const ticketDetailsReload = useSelector((state) => state.tickets.reload);

  const ticketId = window.location.pathname.replace(
    "/app/ticket/TicketDetails/",
    ""
  );

  useEffect(() => {
    setReload(true);
    dispatch(getTicketById(ticketId)).then((res) => {
      setReload(false);
    });
  }, [refresh]);

  const handleDeleteAttachment = (id) => {
    setReload(true);
    dispatch(deleteAttachment(id, ticketId)).then((res) => {
      setRefresh(!refresh);
      setReload(false);
    });
  };

  const handleAssigneesDialogOpen = () => {
    setOpenAssigneesDialog(true);
    dispatch(getEmployee());
  };

  const handleImageDialogOpen = () => {
    setOpenImageDialog(true);
  };
  const handleDialogClose = () => {
    setOpenAssigneesDialog(false);
    setOpenImageDialog(false);
  };

  let navigate = useNavigate();

  // ------------------ assignees -------------------//

  const handleUnassignees = (userId) => {
    setReload(true);
    dispatch(
      unAssignTicket(
        {
          ticketId: ticketDetailsData.id,
          userId: userId,
        },
        ticketId
      )
    ).then((x) => {
      // setSelected(null)
      setRefresh(!refresh);
      setReload(false);
    });
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[2][0] ?? name.split(" ")[1][0]
      }`,
    };
  }

  const handleMouseEnter = (index) => {
    setSelected(index);
  };

  const handleMouseLeave = (index) => {
    setSelected(null);
  };

  const handleOpenPanZoom = (index) => {
    setOpenImageDialog(true);
    setShowPanZoom(index);
  };

  const handleRemoveImageHoverIn = (index) => {
    setSelectedAttachment(index);
  };

  const handleRemoveImageHoverLeave = (index) => {
    setSelectedAttachment(null);
  };

  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      {!ticketDetailsData ? (
        <CustomSpinner />
      ) : (
        <PerfectScrollbar>
          {reload && <LinearProgress />}
          <Card sx={{ padding: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={8} xs={12} md={8}>
                <Stack spacing={2} direction="column">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Card sx={{ paddingX: 2 }}>
                        <CardHeader
                          title={
                            <Typography variant="h4" color="primary">
                              {ticketDetailsData.issuerInfo.fullName}
                            </Typography>
                          }
                          subheader={ticketDetailsData.issuerInfo.phoneNumber}
                          action={
                            <Stack spacing={1} alignItems="center">
                              <Stack
                                direction="row"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                margin={0}
                              >
                                <Typography>
                                  {ticketDetailsData.categoryNameEn}
                                </Typography>
                                {ticketDetailsData.orderId && (
                                  <Tooltip
                                    title="Order Details"
                                    aria-label="edit"
                                  >
                                    <IconButton
                                      color="primary"
                                      aria-label="edit"
                                      onClick={(e) => {
                                        // navigate(`/app/order/${ticketDetailsData.orderId}`);
                                        window.open(
                                          `/app/order/${ticketDetailsData.orderId}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Stack>
                              <TicketStatusMenu
                                ticketId={ticketDetailsData.id}
                                status={Ticket_Status.map((x) =>
                                  x.id == ticketDetailsData.status
                                    ? x.name
                                    : null
                                )}
                              />
                            </Stack>
                          }
                        />
                        <Divider />
                        <Box sx={{ padding: 2 }}>
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Stack
                              direction="row"
                              spacing={3}
                              alignItems="center"
                            >
                              <Typography variant="h4" color="primary">
                                #{ticketDetailsData.number}
                              </Typography>
                              {ticketDetailsData.internal && (
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={"Office Ticket"}
                                />
                              )}
                            </Stack>
                            <Typography>
                              {moment(ticketDetailsData.createdAt).format(
                                "YYYY-MM-DD hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                          <TextField
                            sx={{ marginTop: 2 }}
                            label="Description"
                            size="small"
                            fullWidth
                            multiline
                            rows={8}
                            value={ticketDetailsData.description}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Stack direction="column" spacing={2}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{ alignItems: "center", marginY: 1 }}
                                >
                                  <Typography sx={{ width: "fit-content" }}>
                                    Department:
                                  </Typography>
                                  <DepartmentMenu
                                    ticketId={ticketDetailsData.id}
                                    department={
                                      ticketDetailsData.departmentName
                                    }
                                  />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <Typography>Priority:</Typography>
                                  <TicketPriorityMenu
                                    ticketId={ticketDetailsData.id}
                                    priority={Ticket_Priority.map((x) =>
                                      x.id == ticketDetailsData.priority
                                        ? x.name
                                        : null
                                    )}
                                    onReload={ticketDetailsData.reload}
                                    onSuccess={() => setRefresh(!refresh)}
                                  />
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{ justifyContent: "end", display: "flex" }}
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                sx={{ marginTop: 2 }}
                              >
                                <RolePermission permission="T3-03">
                                  <Box
                                    sx={{ width: "fit-content", marginTop: 2 }}
                                  >
                                    <Tooltip
                                      placement="top"
                                      arrow
                                      title={"Add Assignees"}
                                      onClick={handleAssigneesDialogOpen}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography color="primary">
                                        <PersonAddIcon fontSize="large" />
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </RolePermission>
                                <Stack direction="row" spacing={2}>
                                  {ticketDetailsData.assignees.map(
                                    (data, index) => {
                                      return (
                                        <Box
                                          key={index}
                                          sx={{ position: "relative" }}
                                          onMouseEnter={() =>
                                            handleMouseEnter(index)
                                          }
                                          onMouseLeave={() =>
                                            handleMouseLeave(index)
                                          }
                                        >
                                          {ticketDetailsData &&
                                            ticketDetailsData.assignees.length >
                                              0 && (
                                              <StyledAvatar>
                                                <Tooltip
                                                  placement="top"
                                                  arrow
                                                  title={data.fullName}
                                                >
                                                  <Avatar
                                                    {...stringAvatar(
                                                      data.fullName
                                                    )}
                                                    sx={{
                                                      width: 56,
                                                      height: 56,
                                                      cursor: "pointer",
                                                      ...stringAvatar(
                                                        data.fullName
                                                      ).sx,
                                                    }}
                                                  />
                                                </Tooltip>
                                                {selected == index && (
                                                  <IconButton
                                                    sx={{
                                                      position: "absolute",
                                                      top: "-12px",
                                                      right: "-10px",
                                                    }}
                                                    aria-label="remove"
                                                    onClick={() =>
                                                      handleUnassignees(
                                                        data.userId
                                                      )
                                                    }
                                                    onMouseEnter={() =>
                                                      setHoveredColor(true)
                                                    }
                                                    onMouseLeave={() =>
                                                      setHoveredColor(false)
                                                    }
                                                  >
                                                    <BsFillXCircleFill
                                                      size={16}
                                                      color={
                                                        hoveredColor
                                                          ? "#D32F2F"
                                                          : "grey"
                                                      }
                                                    />
                                                  </IconButton>
                                                )}
                                              </StyledAvatar>
                                            )}
                                        </Box>
                                      );
                                    }
                                  )}
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item lg={4} xs={12} md={4}>
                <Box sx={{ heigth: "100%" }}>
                  <Card sx={{ paddingX: 2 }}>
                    <CardHeader
                      title={
                        <Typography variant="h4" component="div">
                          Timeline
                        </Typography>
                      }
                    />
                    <Divider />
                    <TicketTimelineDialog
                      ticketId={ticketDetailsData.id}
                      status={ticketDetailsData.status}
                    />
                    <Divider sx={{ marginTop: 5 }}>
                      <Chip variant="outlined" label="Attachments" />
                    </Divider>
                    <Box
                      sx={{
                        marginY: 2,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Box>
                        <Grid container spacing={2}>
                          {ticketDetailsData.attachments.map((data, index) => (
                            <Grid
                              key={index}
                              item
                              xs={4}
                              sx={{ position: "relative" }}
                            >
                              <Box
                                sx={{ cursor: "pointer" }}
                                onMouseEnter={() =>
                                  handleRemoveImageHoverIn(index)
                                }
                                onMouseLeave={() =>
                                  handleRemoveImageHoverLeave(index)
                                }
                              >
                                {ticketDetailsData &&
                                  ticketDetailsData.attachments.length > 0 && (
                                    <Box>
                                      <Box
                                        onClick={() =>
                                          handleOpenPanZoom(data.url)
                                        }
                                        component="img"
                                        sx={{
                                          height: "auto",
                                          width: "100%",
                                        }}
                                        alt="itemImage"
                                        src={
                                          process.env.REACT_APP_ENV == "QA"
                                            ? `https://api-qa.orderii.co/ticket-service${data.url}`
                                            : `https://api.orderii.co/ticket-service${data.url}`
                                        }
                                      />
                                      <RolePermission permission="T4-02">
                                        {selectedAttachment == index && (
                                          <Tooltip
                                            placement="top"
                                            arrow
                                            title="Remove"
                                            onClick={() =>
                                              handleDeleteAttachment(data.id)
                                            }
                                          >
                                            <IconButton
                                              sx={{
                                                position: "absolute",
                                                top: "15px",
                                                right: "0px",
                                              }}
                                              aria-label="remove"
                                              onMouseEnter={() =>
                                                setHoveredColor(true)
                                              }
                                              onMouseLeave={() =>
                                                setHoveredColor(false)
                                              }
                                            >
                                              <BsFillXCircleFill
                                                size={16}
                                                color={
                                                  hoveredColor
                                                    ? "#D32F2F"
                                                    : "grey"
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </RolePermission>
                                    </Box>
                                  )}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                    <AssignessList
                      isOpen={openAssigneesDialog}
                      isClosed={handleDialogClose}
                    />
                    <CustomDialog
                      isOpen={openImageDialog}
                      handleClose={handleDialogClose}
                      title="Images"
                      maxWidth="lg"
                    >
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            // minWidth: '500px',
                            width: "50%",
                            height: "auto",
                          }}
                        >
                          <ReactPanZoom
                            image={
                              process.env.REACT_APP_ENV == "QA"
                                ? `https://api-qa.orderii.co/ticket-service${showPanZoom}`
                                : `https://api.orderii.co/ticket-service${showPanZoom}`
                            }
                            alt="Image alt text"
                          />
                        </div>
                      </div>
                    </CustomDialog>
                  </Card>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Card sx={{ paddingX: 2, marginY: 2 }}>
                <CardHeader
                  title={
                    <Typography variant="h4" component="div">
                      Comments
                    </Typography>
                  }
                />
                <Divider />
                <TicketComment ticketId={ticketDetailsData.id} />
              </Card>
            </Box>
          </Card>
        </PerfectScrollbar>
      )}
    </Box>
  );
}
