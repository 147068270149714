import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoDiamond } from "react-icons/io5";
import { MdStarHalf, MdStarOutline } from "react-icons/md";

const LoyaltyTierDialog = ({ open, onClose, data }) => {
  const getTierColor = (tier) => {
    switch (tier) {
      case 0:
        return "gray"; // Silver
      case 1:
        return "orange"; // Gold
      case 2:
        return "blue"; // Diamond
      default:
        return "default";
    }
  };

  const getTierIcon = (tier) => {
    switch (tier) {
      case 0:
        return <MdStarOutline color="gray" size={20} />;
      case 1:
        return <MdStarHalf color="orange" size={20} />;
      case 2:
        return <IoDiamond color="blue" size={20} />;
      default:
        return <IoMdInformationCircleOutline />;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Loyalty Tier Information</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            {getTierIcon(0)}
            <Typography variant="h6" sx={{ color: getTierColor(0) }}>
              Silver Tier
              {data.currentUserLoyaltyTier.customerLoyaltyTier === 0 &&
                " (Current)"}
            </Typography>
          </Stack>
          <Typography>
            Next Order Amount: {data.silver.nextOrderAmount?.toLocaleString()}$
          </Typography>
          <Typography>
            Previous Orders Amount:{" "}
            {data.silver.prevOrderPrice?.toLocaleString()}$
          </Typography>
          <Typography>
            Previous Orders Count: {data.silver.prevOrderCount}
          </Typography>
          <Divider />
          <Stack direction="row" spacing={1}>
            {getTierIcon(1)}
            <Typography variant="h6" sx={{ color: getTierColor(1) }}>
              Gold Tier
              {data.currentUserLoyaltyTier.customerLoyaltyTier === 1 &&
                " (Current)"}
            </Typography>
          </Stack>
          <Typography>
            Next Order Amount: {data.gold.nextOrderAmount?.toLocaleString()}$
          </Typography>
          <Typography>
            Previous Orders Amount: {data.gold.prevOrderPrice?.toLocaleString()}
            $
          </Typography>
          <Typography>
            Previous Orders Count: {data.gold.prevOrderCount}
          </Typography>
          <Divider />
          <Stack direction="row" spacing={1}>
            {getTierIcon(2)}
            <Typography variant="h6" sx={{ color: getTierColor(2) }}>
              Diamond Tier
              {data.currentUserLoyaltyTier.customerLoyaltyTier === 2 &&
                " (Current)"}
            </Typography>
          </Stack>
          <Typography>
            Next Order Amount: {data.diamond.nextOrderAmount?.toLocaleString()}$
          </Typography>
          <Typography>
            Previous Orders Amount:{" "}
            {data.diamond.prevOrderPrice?.toLocaleString()}$
          </Typography>
          <Typography>
            Previous Orders Count: {data.diamond.prevOrderCount}
          </Typography>
        </Stack>
      </DialogContent>
      <Button onClick={onClose}>Close</Button>
    </Dialog>
  );
};

export default LoyaltyTierDialog;
