import {
  Grid,
  Stack,
  Button,
  FormControlLabel,
  Divider,
  Chip,
  Switch,
  Autocomplete,
  TextField,
  Checkbox,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  getAllGiftItem,
  openGiftItemDialog,
  resetGiftItemEditAfterOrderForm,
  updateGiftItemAfterOrder,
} from "src/actions/gifts/giftItem";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";

const EditItemAfterOrderForm = () => {
  const dispatch = useDispatch();

  const giftItem = useSelector((state) => state.giftItems);
  const loading = useSelector((state) => state.giftItems.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: giftItem.editItemAfterOrder,
  });

  const { fields } = useFieldArray({
    control,
    name: "options",
  });

  const handleDialog = () => {
    dispatch(openGiftItemDialog());
  };

  useEffect(() => {
    reset({ ...giftItem.editItemAfterOrder });
  }, [reset, giftItem.editItemAfterOrder]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftItemEditAfterOrderForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
      updateGiftItemAfterOrder(data.id, {
        giftItemCategoryIds: data.giftItemCategoryIds,
        options: data.options,
        filters: data.filters,
        costPrice: data.costPrice,
        sellPrice: data.sellPrice,
        discountPrice: data.discountPrice,
        localExpress: data.localExpress,
        original: data.original,
      })
    ).then((x) => {
      if (x?.status === 200) {
        reset({ ...giftItem.editItemAfterOrder });
        dispatch(resetGiftItemEditAfterOrderForm());
        handleDialog();
        dispatch(getAllGiftItem(pageNo, searchBody));
      }
    });
  };

  return (
    <CustomDialog
      title={"Edit Product After Order"}
      isOpen={giftItem.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="giftItemCategoryIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  fullWidth
                  disabled={loading}
                  options={_GiftCategory || []}
                  getOptionLabel={(option) => option.nameEn}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      required={field?.value?.length <= 0}
                      {...params}
                      label="Category"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    var newIds = value.map((x) => x.id);
                    var oldIds = field.value ?? [];
                    var deselectedId = oldIds?.find(
                      (id) => !newIds.includes(id)
                    );
                    if (deselectedId) {
                      var deselectedFilters = _GiftCategory
                        .find((x) => x.id === deselectedId)
                        .filterCollections.map((x) => x.values)
                        .flat()
                        .map((x) => x.id);

                      var newFilters = watch("filters").filter(
                        (id) => !deselectedFilters.includes(id)
                      );
                      setValue("filters", newFilters, { shouldDirty: true });
                    }
                    setValue(
                      "giftItemCategoryIds",
                      newIds.length > 0 ? newIds : null,
                      { shouldDirty: true }
                    );
                    setValue(
                      "giftItemCategories",
                      value.length > 0 ? value : null
                    );
                  }}
                  value={_GiftCategory?.filter((x) =>
                    field.value ? field.value?.includes(x.id) : null
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="localExpress"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={watch("localExpress") ? "Active" : "Inactive "}
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("localExpress", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Local Express"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="original"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={watch("original") ? "Active" : "Inactive "}
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("original", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Original"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="costPrice"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Cost Price (IQD)"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="sellPrice"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Sell Price (IQD)"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="discountPrice"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  size="small"
                  label="Price After Discount (IQD)"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  {...field}
                />
              )}
            />
          </Grid>
          {watch("giftItemCategories")?.length > 0 ? (
            <Grid item xs={12} md={12}>
              <Divider>
                <Chip
                  variant="outlined"
                  label="Filter Collection"
                  sx={{ my: 2 }}
                />
              </Divider>
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            {watch("giftItemCategories")?.map((category, index) =>
              category?.filterCollections?.length > 0 ? (
                <Box key={index} sx-={{ py: 25 }}>
                  <Typography variant="h3">{category?.nameEn}:</Typography>
                  {category?.filterCollections?.map((option, index) => (
                    <Box key={index}>
                      <Typography variant="h4" key={option.id}>
                        {option.nameEn}:
                      </Typography>
                      {option?.values?.map((value, index) => (
                        <Controller
                          key={index}
                          name="filters"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              key={value.id}
                              control={
                                <Checkbox
                                  value={value.id}
                                  checked={field.value.includes(value.id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setValue(
                                        "filters",
                                        [...field.value, value.id],
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                    } else {
                                      setValue(
                                        "filters",
                                        field.value.filter(
                                          (id) => id !== value.id
                                        ),
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                    }
                                  }}
                                />
                              }
                              label={value.nameEn}
                            />
                          )}
                        />
                      ))}
                    </Box>
                  ))}
                  <Divider sx={{ my: 4 }} />
                </Box>
              ) : null
            )}
          </Grid>
          {giftItem.editItemAfterOrder?.options?.length > 0 ? (
            <Grid item xs={12}>
              <Divider>
                <Chip variant="outlined" label="Options" />
              </Divider>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {giftItem.editItemAfterOrder?.options?.length > 0 && (
              <Stack spacing={2}>
                {fields.map((options, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Chip
                      variant="outlined"
                      label={options.nameEn}
                      color={"success"}
                    />
                    <Controller
                      name={`options[${index}].isActive`}
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              onChange={(e) => {
                                setValue(
                                  `options[${index}].isActive`,
                                  e.target.checked,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              }}
                            />
                          }
                          label="Show Options!"
                        />
                      )}
                    />
                  </Stack>
                ))}
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...giftItem.editItemAfterOrder })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default EditItemAfterOrderForm;
