import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Typography, LinearProgress, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  deleteCategory,
  getCategoryById,
  getCategoryPageNo,
  openCategoryDialog,
  resetForm,
  saveTicketCategoryPageNo,
} from "src/actions/ticket/category";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import CustomSpinner from "src/components/CustomSpinner";
import { CustomDialog } from "src/components/CustomDialog";
import useCanShow from "src/components/CanShow";

export default function TicketCategoryList() {
  const dispatch = useDispatch();

  const editRole = useCanShow("T1-02");
  const deleteRole = useCanShow("T1-03");

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const ticketCategoryData = useSelector((state) => state.ticketCategory);
  const ticketCategory = useSelector((state) => state.ticketCategory.form);
  const pageNo = useSelector((state) => state.ticketCategory.savePageNo);
  const loading = useSelector((state) => state.ticketCategory.reload);

  const [page, setPage] = useState(pageNo ? pageNo : 0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    dispatch(getCategoryPageNo(page + 1)).then((res) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(saveTicketCategoryPageNo(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  function deleteTicketCategory() {
    setOpenDeleteDialog(false);
    dispatch(deleteCategory(ticketCategory.id, page + 1)).then((x) => {
      dispatch(resetForm());
    });
  }

  const TicketCategoryToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteTicketCategory()}
            selectedObject={`${ticketCategory.nameEn} - ${ticketCategory.nameAr}`}
          ></CustomDialog>
        )}
      </Box>
    );
  };

  var columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "isActive",
      headerName: "Is Active !",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isActive ? "Active" : "Deactivate"}
          color={params.row.isActive ? "primary" : "error"}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / HH:mm")}{" "}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Operations",
      flex: 1,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            dispatch(getCategoryById(params.row.id)).then((x) => {
              dispatch(openCategoryDialog());
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            dispatch(getCategoryById(params.row.id)).then((x) => {
              setOpenDeleteDialog(true);
            });
          }}
        />,
      ],
    },
  ];

  columns =
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("T1-02") ||
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("T1-03")
      ? columns
      : columns.filter((x) => x.field !== "operations");

  return (
    <Card>
      {TicketCategoryToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!ticketCategoryData ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 200px)" }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                loading={loading}
                rows={ticketCategoryData.data}
                columns={columns}
                rowCount={ticketCategoryData.rowCount}
                rowHeight={57}
                disableSelectionOnClick
                pagination
                page={page}
                pageSize={10}
                paginationMode="server"
                rowsPerPageOptions={[10]}
                onPageChange={(newPage) => handlePageChange(newPage)}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
