import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { MdAirplanemodeActive } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import { Add } from "@mui/icons-material";
import { openDialog } from "src/actions/internationalShippingCompany";
import { useDispatch, useSelector } from "react-redux";
import InternationalShippingCompanyForm from "src/pages/cross/shippingCompany/internationalShippingCompany/from/Form";

const InternationalShippingCompanyHeader = () => {
  const dispatch = useDispatch();

  const dialog = useSelector(
    (state) => state.internationalShippingCompany.openDialog
  );

  return (
    <>
      <Helmet>
        <title> International Shipping Company | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                International Shipping Company
              </Typography>
            }
            avatar={<MdAirplanemodeActive size={20} />}
            action={
              <RolePermission permission="51-09">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Add />}
                  onClick={() => dispatch(openDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {dialog ? <InternationalShippingCompanyForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default InternationalShippingCompanyHeader;
