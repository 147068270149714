import {
  TextField,
  Grid,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  createVariant,
  deleteVariant,
  openVariantDialog,
  resetVariantForm,
} from "src/actions/gifts/variant";
import {
  getAllGiftItem,
  getGiftItemVariants,
} from "src/actions/gifts/giftItem";
import RolePermission from "src/components/RolePermission";
import { DeleteForever } from "@mui/icons-material";
import { useState } from "react";

const VariantForm = (props) => {
  const dispatch = useDispatch();

  const variant = useSelector((state) => state.variant);
  const isInGiftOrder = useSelector((state) => state.variant.isInGiftOrder);
  const loading = useSelector((state) => state.variant.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const groupedOptions = useSelector(
    (state) => state.giftItems.updateForm.groupedOptions
  );
  const _ItemId = useSelector((state) => state.giftItems.updateForm.id);
  const _ItemVariants = useSelector((state) => state.giftItems.ItemVariants);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteData, setOpenDeleteData] = useState({});

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    // setValue,
    // watch,
  } = useForm({
    defaultValues: variant.form,
  });

  const handleDialog = () => {
    dispatch(openVariantDialog());
  };

  useEffect(() => {
    dispatch(getGiftItemVariants(_ItemId));
  }, [_ItemId, dispatch]);

  useEffect(() => {
    reset({ ...variant.form });
  }, [reset, variant.form]);

  useEffect(() => {
    return () => {
      dispatch(resetVariantForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
      createVariant({
        giftItemId: _ItemId,
        quantity: parseInt(data.quantity),
        giftItemOptionIds: data.giftItemOptionIds,
      })
    ).then((x) => {
      dispatch(getGiftItemVariants(_ItemId));
      reset({ ...variant.form });
      dispatch(resetVariantForm());
      // handleDialog();
      dispatch(getAllGiftItem(pageNo, searchBody));
    });
  };

  const handleDeleteVariant = (id) => {
    dispatch(deleteVariant(id)).then((x) => {
      dispatch(getGiftItemVariants(_ItemId));
      reset({ ...variant.form });
      dispatch(resetVariantForm());
      dispatch(getAllGiftItem(pageNo, searchBody));
      setOpenDeleteDialog(false);
    });
  };

  return (
    <CustomDialog
      title={"Add Product Variations"}
      isOpen={variant.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <CustomDialog
        isOpen={openDeleteDialog}
        handleClose={() => {
          setOpenDeleteDialog(false);
        }}
        isActiveDelete
        isRequierdActions
        title="Delete confirmation"
        maxWidth="sm"
        onDelete={() => handleDeleteVariant(openDeleteData.id)}
        selectedObject={`SKU: ${openDeleteData.sku}`}
      ></CustomDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {_ItemVariants ? (
            <Grid item xs={12} md={12}>
              <Typography variant="h4" paddingY={2}>
                Variants:
              </Typography>
              <Stack spacing={2}>
                {_ItemVariants.map((variant, index) => (
                  <Stack spacing={1} key={index}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography>SKU:</Typography>
                      <Chip
                        variant="outlined"
                        label={variant.sku}
                        key={index}
                        size="small"
                      />
                      {isInGiftOrder ? null : (
                        <RolePermission permission="48-02">
                          <Button
                            sx={{ backgroundColor: "transparent" }}
                            color="error"
                            aria-label="delete"
                            onClick={(e) => {
                              setOpenDeleteDialog(true);
                              setOpenDeleteData(variant);
                            }}
                          >
                            <DeleteForever />
                          </Button>
                        </RolePermission>
                      )}
                    </Stack>
                    {variant.giftItemOptions.map((option, index) => (
                      <Stack direction="row" spacing={2} key={index}>
                        <Typography>Options:</Typography>
                        <Chip
                          variant="outlined"
                          label={option.nameEn}
                          key={index}
                          size="small"
                        />
                      </Stack>
                    ))}
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Typography>Quantity: {variant.quantity}</Typography>
                      <Typography>
                        Quantity At Creation Date:{" "}
                        {variant.quantityAtCreationDate}
                      </Typography>
                    </Stack>
                    <Divider />
                  </Stack>
                ))}
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Controller
              name="giftItemOptionIds"
              control={control}
              render={({ field }) =>
                Object.keys(groupedOptions).map((key) => (
                  <Box key={key}>
                    <Typography variant="h4">
                      {groupedOptions[key][0].options.nameEn}:
                    </Typography>
                    {groupedOptions[key].map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            value={option.id}
                            checked={field.value.includes(option.id)}
                            onChange={(e) => {
                              const otherIds = groupedOptions[key].map(
                                (o) => o.id
                              );
                              const newValues = field.value.filter(
                                (id) => !otherIds.includes(id)
                              );
                              if (e.target.checked) {
                                field.onChange([...newValues, option.id]);
                              } else {
                                field.onChange(newValues);
                              }
                            }}
                          />
                        }
                        label={option.nameEn}
                      />
                    ))}
                    <Divider sx={{ my: 2 }} />
                  </Box>
                ))
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Quantity"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...variant.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default VariantForm;
