import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Button,
  LinearProgress,
  Grid,
  Divider,
  Typography,
  Autocomplete,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import {
  deleteDepartment,
  getByIdDepartment,
  getDepartment,
  updateDepartment,
} from "src/actions/department/department";
import { getEmployee } from "src/actions/department/employee";
import {
  createDepartmentEmployee,
  deleteDepartmentEmployee,
  getDepartmentEmployee,
} from "src/actions/department/departmentEmployee";
import { Stack } from "@mui/system";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";
import useCanShow from "src/components/CanShow";

export default function DepartmentList() {
  const dispatch = useDispatch();

  const editRole = useCanShow("D2-01");
  const deleteRole = useCanShow("D2-03");

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openDepartmentDialog, setOpendeDartmentDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [employeeId, setEmployeeId] = useState("");

  const departmentData = useSelector((state) => state.department.data);
  const departmentById = useSelector((state) => state.department.getByID);

  const employeeData = useSelector((state) => state.employee.data);

  const departmentEmployee = useSelector(
    (state) => state.departmentEmployee.data
  );

  const { control, reset, handleSubmit } = useForm({
    defaultValues: departmentById,
  });

  useEffect(() => {
    setReload(true);
    dispatch(getEmployee()).then((res) => {
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDepartmentByID = (id) => {
    setReload(true);
    dispatch(getByIdDepartment(id)).then((res) => {
      setReload(false);
    });
  };

  useEffect(() => {
    reset(departmentById);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentById]);

  useEffect(() => {
    setReload(true);
    dispatch(getDepartment()).then((res) => {
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleDepartmentDialogOpen = () => {
    setOpendeDartmentDialog(true);
    reset();
  };

  const handleDepartmentDialogClose = () => {
    setOpendeDartmentDialog(false);
  };

  function updateSelectedDepartment(data) {
    setOpendeDartmentDialog(false);
    dispatch(updateDepartment(departmentById.id, data)).then((res) => {
      setRefresh(!refresh);
    });
  }

  function deleteSelectedDepartment() {
    setOpenDeleteDialog(false);
    dispatch(deleteDepartment(departmentById.id)).then((res) => {
      setRefresh(!refresh);
    });
  }

  function createDepartmentemployee() {
    setReload(true);
    dispatch(
      createDepartmentEmployee({
        departmentId: departmentById.id,
        employeeId: employeeId.id,
      })
    ).then((res) => {
      setReload(false);
      setRefresh(!refresh);
    });
  }

  function handleDepartmentEmployees(departmentId) {
    setReload(true);
    dispatch(getDepartmentEmployee(departmentId)).then((res) => {
      setReload(false);
    });
  }

  function handleDeleteDepartmentEmployees(departmentId, employeeId) {
    setReload(true);
    dispatch(deleteDepartmentEmployee(departmentId, employeeId)).then((res) => {
      setReload(false);
      setRefresh(!refresh);
    });
  }

  const departmentToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedDepartment()}
            selectedObject={departmentById.name}
          ></CustomDialog>
        )}
        <CustomDialog
          isOpen={openDepartmentDialog}
          handleClose={handleDepartmentDialogClose}
          title={"MANAGE DEPARTMENT"}
          maxWidth="sm"
          // onClear={clearTexts}
        >
          {reload ? <LinearProgress /> : null}
          <form onSubmit={handleSubmit(updateSelectedDepartment)}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="Name"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button type="submit" variant="contained" color="primary">
                  Edit
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Divider sx={{ marginBottom: 2 }}>
                <Chip variant="outlined" label="ADD EMPLOYEES" />
              </Divider>
              <Autocomplete
                id={employeeData.id}
                options={employeeData}
                getOptionLabel={(option) => option.username}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Employees" />
                )}
                onChange={(event, value) => {
                  setEmployeeId(value ?? null);
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={createDepartmentemployee}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {departmentEmployee.length <= 0 ? null : (
                <Divider>
                  <Chip variant="outlined" label="DEPARTMENT EMPLOYEES" />
                </Divider>
              )}
              <Box>
                {departmentEmployee.map((data, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography>
                      {index + 1}. {data.username}
                    </Typography>
                    <Typography>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={(e) => {
                          handleDeleteDepartmentEmployees(
                            data.departmentId,
                            data.employeeId
                          );
                        }}
                      >
                        <Tooltip title="Delete" aria-label="delete">
                          <DeleteForeverIcon />
                        </Tooltip>
                      </IconButton>
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Grid>
          </Grid>
        </CustomDialog>
      </Box>
    );
  };

  var columns = [
    {
      field: "#",
      headerName: "#",
      width: 5,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      headerAlign: "center",
    },
    {
      field: "count",
      headerName: "Employee Count",
      width: 300,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Operations",
      width: 250,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            handleDepartmentEmployees(params.id);
            handleDepartmentByID(params.id);
            handleDepartmentDialogOpen();
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            handleDepartmentByID(params.id);
            setOpenDeleteDialog(true);
          }}
        />,
      ],
    },
  ];

  columns =
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("D1-02") ||
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("D1-03")
      ? columns
      : columns.filter((x) => x.field !== "operations");

  return (
    <Card>
      {departmentToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!departmentData ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 200px)" }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                loading={reload}
                rows={departmentData}
                columns={columns}
                rowHeight={65}
                disableSelectionOnClick
                pagination
                paginationMode="client"
                rowsPerPageOptions={[10, 25, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
