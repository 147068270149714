import axios from "../axios";

class UserService {
  getAllFiltered(pageNumber, pageSize, data) {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== null && value !== "" && value !== undefined
      )
    );
    return axios.post(
      `/User/AllFiltered/${pageNumber}?pageSize=${pageSize}`,
      filteredData
    );
  }

  getFilteredOnly(pageNumber, pageSize, data) {
    return axios.post(
      `/User/getFilteredOnly/${pageNumber}?pageSize=${pageSize}`,
      data
    );
  }

  getById(id) {
    return axios.get(`/User/getById/${id}`);
  }

  create(data) {
    return axios.post("/User/createcustomer", data);
  }

  update(data) {
    return axios.put(`/User/update`, data);
  }

  changePassword(data) {
    return axios.put(`/User/ChangePassword`, data);
  }

  resetPassword(data) {
    return axios.put(`/User/ChangeuserPassword`, data);
  }

  changeStatus(id, status, userDeactivationReasonId) {
    return axios.put(
      `/User/changeStatus?userId=${id}&status=${status}&userDeactivationReasonId=${userDeactivationReasonId}`
    );
  }

  loginHistory(id) {
    return axios.get(`/user/loghistory/${id}`);
  }

  getByPhoneNumber(phoneNumber) {
    return axios.get(`/user/getbyphone/${phoneNumber}`);
  }

  getByToken() {
    return axios.get(`/user/getbytoken`);
  }

  changeServiceType(data) {
    return axios.put(`/User/modifyservicetype/${data}`);
  }

  getPhoneCodeHistory(phoneNumber) {
    return axios.get(`/user/phonecodeshistory?PhoneNumber=${phoneNumber}`);
  }

  modifyCustomerTier(id, data) {
    return axios.put(`/User/modifycustomertier/${id}`, data);
  }
}

export default new UserService();
