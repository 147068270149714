import {
  LOCAL_SHIPPING_COMPANY_RESET_FORM,
  LOCAL_SHIPPING_COMPANY_GET_BY_ID,
  LOCAL_SHIPPING_COMPANY_PAGE_NUMBER,
  LOCAL_SHIPPING_COMPANY_OPEN_DIALOG,
  LOCAL_SHIPPING_COMPANY_RELOAD,
  LOCAL_SHIPPING_COMPANY_GET_ALL,
  LOCAL_SHIPPING_COMPANY_SEARCH_BODY,
  LOCAL_SHIPPING_COMPANY_GET_LIST,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    name: "",
    phoneNumber: "",
    address: "",
  },
  form: {
    id: null,
    name: "",
    phoneNumber: "",
    address: "",
  },
  localShippingCompanyData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  localShippingCompanyList: [],
};

function localShippingCompanyReducer(
  localShippingCompany = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case LOCAL_SHIPPING_COMPANY_GET_ALL:
      localShippingCompany.localShippingCompanyData.data = payload.data;
      localShippingCompany.localShippingCompanyData.total = payload.pageCount;
      localShippingCompany.localShippingCompanyData.rowCount = payload.rowCount;
      return localShippingCompany;

    case LOCAL_SHIPPING_COMPANY_GET_LIST:
      localShippingCompany.localShippingCompanyList = payload;
      return localShippingCompany;

    case LOCAL_SHIPPING_COMPANY_GET_BY_ID:
      localShippingCompany.form = payload;
      return localShippingCompany;

    case LOCAL_SHIPPING_COMPANY_RESET_FORM:
      localShippingCompany.form = initialState.form;
      return localShippingCompany;

    case LOCAL_SHIPPING_COMPANY_PAGE_NUMBER:
      return { ...localShippingCompany, pageNo: payload };

    case LOCAL_SHIPPING_COMPANY_SEARCH_BODY:
      return { ...localShippingCompany, searchBody: payload };

    case LOCAL_SHIPPING_COMPANY_OPEN_DIALOG:
      return {
        ...localShippingCompany,
        openDialog: !localShippingCompany.openDialog,
      };

    case LOCAL_SHIPPING_COMPANY_RELOAD:
      return {
        ...localShippingCompany,
        reload: !localShippingCompany.reload,
      };

    default:
      return localShippingCompany;
  }
}

export default localShippingCompanyReducer;
